import React, {useState} from 'react'
import "./auth.css"
import axios from 'axios'
import {addpassword, adduser, addusername} from "../redux/dataActions"
import {useDispatch, useSelector} from 'react-redux'
import Cookies from 'universal-cookie'


const SignIn = (props) => {

    const signinurl = 'http://192.168.1.136/api/v01/api-login.php'

    const dispatch = useDispatch()
    const email = useSelector((state) => state.email)
    const password = useSelector((state) => state.password)
    const user = useSelector((state) => state.user)
    const phone = useSelector((state) => state.phone)
    const username = useSelector((state) => state.username)
    const [err, setErr] = useState(null)
    const [loginInput, setLoginInput] = useState(1)
    const cookies = new Cookies()
    const setShow = props.setShow

    const data = {
        username: username,
        password: password,
        email: email,
        phone: phone
    }


    function login() {
        axios.post(signinurl,
            data
        )
            .then(res => {
                if (res.data.status === 100) {

                    console.log(res.data.data.username)
                    dispatch(adduser(res.data.data.username))
                    window.location.reload()
                    console.log(res)

                } else if (res.data.error_message) {
                    setErr(res.data.error_message)
                } else {
                    console.log(res)
                }
            }).catch(err => console.log(err))
    }

    return (
        <div id='shadow'
             className='d-flex w-25 bg-light flex-column m-auto text-dark justify-content-center align-items-end p-3 rounded'>
            <div style={{fontSize: '20px', fontWeight: '600'}}>
                ورود به حساب کاربری
            </div>


            <label className='mt-3'>نام کاربری / ایمیل / شماره تماس</label>
            <input onChange={(e) => dispatch(addusername(e.target.value))} className='form-control w-75 mt-2 text-end'
                   placeholder='نام کاربری / ایمیل / شماره تماس '/>


            <label className='mt-3'>پسوورد</label>
            <input onChange={(e) => dispatch(addpassword(e.target.value))} className='form-control w-75 mt-2 text-end'
                   type='password' placeholder='پسوورد'/>
            <div className='d-flex flex-row justify-content-between w-100'>
                <button id='button' onClick={login}> ورود</button>
                <button id='elsebuttton' onClick={() => setShow(show => !show)}>ساخت اکانت جدید</button>
            </div>

        </div>
    )
}

export default SignIn