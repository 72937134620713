import React, {useEffect, useState} from 'react';
import "../../../App.css"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faAnglesLeft,
    faAnglesRight,
    faChevronLeft,
    faChevronRight,
    faEdit,
    faPlus,
    faUserMinus
} from '@fortawesome/free-solid-svg-icons'
import $ from "jquery";
import Adduser from './Adduser';
import Removeuser from './Removeuser';
import EditUser from './EditUser';

const GroundForm = (props) => {

    const [datas, setDatas] = useState([])
    const [total, setTotal] = useState()
    const [id, setId] = useState()
    const [firstname, setFirstname] = useState()
    const [lastname, setLastname] = useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()
    const [deletearr, setDeletearr] = useState(0)
    const [page, setPage] = useState(1)
    const [rows, setRows] = useState(10)
   
    function show(e) {
        $(e).toggle(500)
    }



    return (
        <div className=' h-100 d-flex justify-content-center align-items-start bg-light mt-1 rounded'
             style={{width: '99%'}} dir='rtl'>
            <div id='adduser2' style={{display: "none"}}>
                <Adduser show={show}/>
            </div>
            <div id='removeRow' style={{display: "none"}}>
                <Removeuser firstname={firstname}  id={id} show={show}/>
            </div>
            <div id='editRow' style={{display: "none"}}>
                <EditUser firstname={firstname} lastname={lastname} phone={phone} email={email} 
                          id={id} show={show}/>
            </div>

            <div className='w-100 rounded' id={props.id}>
                <div className='w-100 rounded'>
                    <div id='underline' className=' my-1 pt-2 w-100 mt-1 text-center'><span
                        className='m-3 w-100 mx-auto h5'> مشخصات زمین<div className='align-middle d-flex justify-content-start align-items-center'>
                        <button id='showdivadduser' className=' rounded p-2 me-2'
                                onClick={() => show("#adduser2")}>
                            <FontAwesomeIcon className='text-light mx-2 align-middle fa-lg' icon={faPlus}/>
                            افزودن زمین
                        </button>
    
                    </div>
                        </span>
                    </div>

                    <div id='showhelp' style={{display: "none"}}>
                        <div><FontAwesomeIcon className='text-danger' icon={faUserMinus}/> : پاک کردن کاربر (per-id)
                        </div>
                        <div><FontAwesomeIcon className='text-warning' icon={faEdit}/> : ویرایش کاربر (per-id)</div>
                    </div>
                    <div className='w-100' id='tableRes' style={{overflowY: "scroll", height: "400px"}}>
                        <table className='table '>

                            <thead id='theadbg' style={{fontSize: '12px'}}>
                            <tr>
                                <th id='test' className='d-flex'>#</th>
                                <th>مساحت</th>
                                <th>نوع زمین</th>
                                <th>نوع کاربری</th>
                                <th> حدود اربعه</th>
                                <th> جنوبا</th>
                                <th>غربا</th>
                                <th>عمق چاه</th>
                                <th>تاریخ حفر چاه</th>
                                <th> فعال</th>
                                <th>اطلاعات پروانه</th>
                                <th>شماره پروانه</th>
                                <th> عمق ثبت شده در پروانه</th>
                                <th>اطلاعات سند</th>
                                <th> نوع مالکیت</th>
                                <th> آدرس زمین</th>


                            </tr>
                            </thead>

                            <tbody>
                       

                            {datas.map((data, id) => (
                                <tr className='align-middle' style={{color: "#48494bd5"}} key={id}>

                                    <td>
                                        {data.id}
                                    </td>
                                    <td>

                                        {data.create_time}

                                    </td>
                                    <td>

                                        {data.id_creator}

                                    </td>
                                    <td>

                                        {data.id_modify}

                                    </td>
                                    <td>

                                        {data.last_update_time}

                                    </td>
                                    <td>

                                        {data.permission_groupe_access}

                                    </td>
                                    <td>

                                        {data.username}

                                    </td>
                                    <td>

                                        {data.status}

                                    </td>
                                    <td className='align-middle'>

                                        <FontAwesomeIcon id='showEdit' className='text-warning h5'
                                                         onClick={() => setId(data.id) & setLastname(data.lastname) & setFirstname(data.firstname) & setEmail(data.email) & setPhone(data.phone) & show("#editRow")}
                                                         icon={faEdit}/>
                                        <FontAwesomeIcon id='showRemove' className='h5 me-3' style={{color: "red"}}
                                                         onClick={() => setId(data.id) & setFirstname(data.firstname) & show("#removeRow")}
                                                         icon={faUserMinus}/>

                                    </td>
                                </tr>
                            ))}

                            </tbody>


                        </table>
                    </div>
                    <div className='d-flex j text-center w-100 flex-row' id='footertable'
                         style={{color: "#48494bd5", fontSize: '12px'}}>
                        <div
                            className='row justify-content-md-start w-50 rounded-bottom align-items-center align-middle'
                            id='footerres' style={{color: "#48494bd5"}}>

                            <div className='col-1 me-1'>
                                <select onChange={(e) => {
                                    setRows(e.target.value)
                                }}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>

                            <div className='col-1 me-4'>
                                <FontAwesomeIcon className='fa-lg' icon={faAnglesRight} onClick={() => {
                                    setPage(1)
                                }}/>
                            </div>

                            <div className='col-1 me-2'>
                                {page < 2 ?
                                    <FontAwesomeIcon className='fa-lg text-danger' icon={faChevronRight}/>
                                    :
                                    <FontAwesomeIcon className='fa-lg text-success' icon={faChevronRight}
                                                     />}
                            </div>

                            <div className=' col-1'>
                                <div className='d-flex flex-row ms-1 w-100 text-center '
                                     style={{borderRadius: "20px"}}>صفحه
                                    <input style={{width: "40px"}} className='bg-dark text-light  text-center'
                                           onChange={(e) => {
                                               setPage(e.target.value)
                                           }} value={page}/></div>
                            </div>

                            <div className='col-1 d-flex flex-row me-5'>
                                <div className='mx-2 justify-content-start d-flex'>
                                    <div>از</div>
                                    <div>1</div>
                                </div>
                            </div>

                            <div className=' col-1'>
                                {total < page * rows ?
                                    <FontAwesomeIcon className='fa-lg text-danger' icon={faChevronLeft}/>
                                    :
                                    <FontAwesomeIcon className='fa-lg text-success' icon={faChevronLeft}
                                                   />}
                            </div>

                            <div className='col-1'>
                                <FontAwesomeIcon className='fa-lg' icon={faAnglesLeft} />
                            </div>
                        </div>
                        <div className='me-auto ms-4'>کل آیتم ها : {total}</div>
                        <div className='me-auto d-flex flex-row ms-4'>نمایش آیتم
                            های {page * rows - rows + 1} تا {total < page * rows ? <>{total}</> : <>{page * rows}</>}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GroundForm