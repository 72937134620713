import React from 'react'
import { useDispatch ,useSelector } from 'react-redux'
import{
    sidebg, sidefont
  } from "../redux/dataActions"
import axios from 'axios'

const Theme = () => {
    const dispatch = useDispatch()
    const sidebarbg = useSelector((state)=>state.sidebar_bg)
    const sidefont2 = useSelector((state)=>state.sidebar_font)
    const id = useSelector((state)=>state.id)
console.log(id)

    const url = 'http://192.168.1.136/api/v01/api-signup.php'

    const data = {
      data_option : 'theme_update',
      id:id,
      theme_data:{


        sidebarbg : sidebarbg,
        sidefont : sidefont2,
      }
     
    }

    const userTheme =()=>{
      
        axios.post(url,data).then(res =>{
          console.log(res)
        })
      
    }
    
    const defaultTheme =()=>{
        dispatch(sidebg("rgba(255, 255, 255, 0.9)"));
        dispatch(sidefont("#4B5152"));
    }




  return (

    <div className='w-75 d-flex bg-light flex-column justify-content-center align-items-end p-3' id='shadow'>
        <label>رنگ پس زمینه  منو سمت راست</label>
        <input  type="color" onChange={(e)=>dispatch(sidebg(e.target.value))}  className='form-control w-25 mt-3'/> 
        {sidebarbg}
        <label className='mt-3'>رنگ  فونت  منو سمت راست</label>
        <input  type="color" onChange={(e)=>dispatch(sidefont(e.target.value))}  className='form-control w-25 mt-3'/> 
        {sidefont2}

<div className='d-flex flex-row justify-content-between w-100 mt-3'> 
<button className='rounded bg-success p-2 text-light align-self-center' onClick={userTheme}>
          ذخیره تم کاربری
        </button>
        
        <button className='rounded p-2 text-light align-self-center' style={{backgroundColor:'#4276DD'}} onClick={defaultTheme}>
         بازگشت به حالت اولیه
        </button>
</div>
     

    </div>

  )
}

export default Theme