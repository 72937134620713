import React, {useState} from 'react'

import '../Table/Tabs.css';

import {DateRangePicker} from "react-advance-jalaali-datepicker";
import City from './City';


const Tabs = () => {

    const [tab, setTab] = useState(<City/>)

    return (
        <div className='text-dark d-flex flex-column justify-content-center w-100 h-100 align-items-center '
             id='container' dir='rtl'>
            <div className='d-flex flex-row ms-auto h-100' id='radius'>
                <button onClick={() => {
                    setTab(<City/>)
                }} className='p-2 rounded-end px-4'>قاره
                </button>
                <button onClick={() => {
                    setTab(<City/>)
                }} className='p-2 px-4'>کشور
                </button>
                <button onClick={() => {
                    setTab(<City/>)
                }} className='p-2 px-4'>استان
                </button>
                <button onClick={() => {
                    setTab(<City/>)
                }} className='p-2 px-4'>شهر
                </button>
                <button onClick={() => {
                    setTab(<City/>)
                }} className='p-2 px-4'>روستا
                </button>
            
          
            </div>
            <div className='w-100' id='tab'>{tab}</div>
        </div>
    )
}

export default Tabs