import React from 'react'
import {DateRangePicker} from "react-advance-jalaali-datepicker";

const Vacation = () => {
    return (
        <div id='shadow'
             className='d-flex w-75 bg-light flex-column m-auto text-dark justify-content-center align-items-center p-3 rounded'
             style={{fontSize: '16px', fontWeight: '600'}}>
            <div style={{fontSize: '20px', fontWeight: '600', color: '#4D4E4F'}}>
                فرم درخواست مرخصی
            </div>
            <div className='d-flex flex-row ms-auto my-3 align-items-center' dir='rtl'>
                اینجانب <input className='form-control w-15 mt-2 text-end mx-2'/> به سمت <input
                className='form-control w-10 mt-2 text-end mx-2'/>
                به مدت <input className='form-control w-15 mt-2 text-end mx-2'/> روز / از ساعت <input
                className='form-control w-5 mt-2 text-end mx-2'/>
                تا ساعت <input className='form-control w-5 mt-2 text-end mx-2'/>
            </div>
            <div className='d-flex flex-row ms-auto my-2 align-items-center w-100' dir='rtl'>
                در تاریخ <DateRangePicker
                placeholder="انتخاب تاریخ"
                format="jYYYY/jMM/jDD"
                id="datePicker"
                preSelected="1396/05/15"/>
                نیاز به مرخصی /
                <div className=' form-switch d-flex flex-row justify-content-start'>
                    <div className=' text-dark' style={{color: 'black', fontWeight: '600'}}> استحقاقی</div>
                    <input className='ms-auto  form-control form-check-input' id="flexSwitchCheckChecked"
                           type="checkbox" role="switch"/>
                </div>
                <div className=' form-switch d-flex flex-row justify-content-start'>
                    <div className=' text-dark' style={{color: 'black', fontWeight: '600'}}> استعلاجی</div>
                    <input className='ms-auto  form-control form-check-input' id="flexSwitchCheckChecked"
                           type="checkbox" role="switch"/>
                </div>
                <div className=' form-switch d-flex flex-row justify-content-start'>
                    <div className=' text-dark' style={{color: 'black', fontWeight: '600'}}> بدون حقوق</div>
                    <input className='ms-auto  form-control form-check-input' id="flexSwitchCheckChecked"
                           type="checkbox" role="switch"/>
                </div>
                دارم .
            </div>
            <div className='d-flex flex-row ms-auto my-2 align-items-center w-100' style={{color: '#4D4E4F'}} dir='rtl'>
                خواهشمند است در صورت صلاحدید موافقت نمایید.
                <br/>
                ضمنا متعهد میشوم در صورت نبودن اینجانب خللی در کار ایجاد نمی گردد .
                <div className='me-auto ms-5'>امضا</div>
            </div>
            <div className='mt-3' style={{border: '1px solid #4D4E4F', width: '100%'}}></div>
            <div className='d-flex flex-row ms-auto my-2 align-items-center w-100' style={{color: '#4D4E4F'}} dir='rtl'>
                <div className=' form-switch d-flex flex-row justify-content-start'>
                    <div className=' text-dark' style={{color: 'black', fontWeight: '600'}}> موافقت میشود</div>
                    <input className='ms-auto me-2 form-control form-check-input' id="flexSwitchCheckChecked"
                           type="checkbox" role="switch"/>
                </div>
                <div className=' form-switch d-flex flex-row justify-content-start'>
                    <div className=' text-dark' style={{color: 'black', fontWeight: '600'}}> موافقت نمیشود</div>
                    <input className='ms-auto me-2 form-control form-check-input' id="flexSwitchCheckChecked"
                           type="checkbox" role="switch"/>
                </div>
                <div className='me-auto ms-5'>امضا مدیر</div>
            </div>
        </div>
    )
}

export default Vacation