import React, {useEffect, useState ,useRef,} from "react";
import {MapContainer, TileLayer, useMap,FeatureGroup} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { EditControl } from "react-leaflet-draw";
import { GeometryUtil } from "leaflet";
import './Map.css';
import icon from "./constants";
import Select from 'react-select'
import InfClickedpos from "./InfClickedpos";
import Routing from "./Routing";
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css"
import { OpenStreetMapProvider ,GeoSearchControl} from "leaflet-geosearch";


export default function LeafletgeoSearch() {
    const map = useMap(); //here use useMap hook
  
    useEffect(() => {
      const provider = new OpenStreetMapProvider();
  
      const searchControl = new GeoSearchControl({
        provider,
        marker: {
          icon
        }
      });
  
      map.addControl(searchControl);
  
      return () => map.removeControl(searchControl)
    }, []);
  
    return null;
  }