import React, {useEffect, useState} from 'react'
import {
    faBell,
    faEdit,
    faEnvelope,
    faExpand,
    faGear,
    faMagnifyingGlass,
    faPowerOff,
    faUser
} from '@fortawesome/free-solid-svg-icons';
import '../App.css';
import logo from './images/logo.jpg';
import {useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import axios from 'axios';
import {Link} from 'react-router-dom'

function Topmenu(props) {

    const [full, setFull] = useState(null)
    const [showprof, setShowprof] = useState(false)
    const [showmsg, setShowmsg] = useState(false)
    const [shownotif, setShownotif] = useState(false)
    const user = useSelector((state) => state.user)

    const url = 'http://192.168.1.136/api/v01/api-logoute.php'

    useEffect(
        () => {
            if (full)
                document.documentElement.requestFullscreen()
            else if (full === null) {
                <></>
            } else
                document.exitFullscreen()
        },
        [full]
    )

    function signOut() {
        axios.post(url, {}).then(res => {
            if (res.data.status === 0) {
                props.setShow(false)
                sessionStorage.clear()
            }
        })
    }


    return (

        <div className='d-flex flex-row p-2 align-items-center justify-content-start rounded' id='topmenubg'
             style={{height: "5vh", marginLeft: "8%", marginTop: "0.5%"}}>

            {showprof ? <>
                <div id='showMsg' className='profdiv d-flex flex-column align-items-end ' style={{width: "200px"}}>
                    <div className='mx-auto text-dark font-weight-bold fa-lg '> {user}</div>
                    <div className='mt-2 mx-auto'>طراح وب</div>
                    <div className='w-100 mt-4 mb-2' style={{height: "1px", backgroundColor: "#BEC5DA"}}></div>
                    <Link to="/profile">پروفایل من<FontAwesomeIcon style={{fontSize: "25", color: "#92979D"}}
                                                                   className='ms-2'
                                                                   icon={faUser}/></Link>
                    <div className='w-100 mt-2 mb-2' style={{height: "1px", backgroundColor: "#BEC5DA"}}></div>
                    <div>ویرایش نمایه<FontAwesomeIcon style={{fontSize: "25", color: "#92979D"}} className='ms-2'
                                                      icon={faEdit}/></div>
                    <div className='w-100 mt-2 mb-2' style={{height: "1px", backgroundColor: "#BEC5DA"}}></div>
                    <div> تنظیمات حساب<FontAwesomeIcon style={{fontSize: "25", color: "#92979D"}} className='ms-2'
                                                       icon={faGear}/></div>
                    <div className='w-100 mt-2 mb-2' style={{height: "1px", backgroundColor: "#BEC5DA"}}></div>
                    <button onClick={signOut}
                            style={{backgroundColor: 'rgba(1,1,1,0)', color: '#524F4F'}}>خروج<FontAwesomeIcon
                        style={{fontSize: "25", color: "#92979D"}} className='ms-2'
                        icon={faPowerOff}/></button>
                </div>
            </> : <></>}

            {showmsg ? <>
                <div id='showMsg' className='msgdiv flex-column align-items-end' style={{width: "300px"}}>
                    <div className=''> شما 2 اعلان خوانده نشده دارید</div>
                    <div className='w-100 mt-3 mb-2' style={{height: "1px", backgroundColor: "#BEC5DA"}}></div>
                    <div className='d-flex flex-column w-100 align-items-end'>
                        <div className='d-flex flex-row'>
                            <div className='me-3'>محل نمایش متن پیام</div>
                            <div><img className='rounded-circle' src={logo} alt="Logo" width={40} height={40}/></div>
                        </div>
                        <div className='text-secondary me-auto'>تاریخ پیام</div>
                    </div>

                    <div className='w-100 mt-2 mb-2' style={{height: "1px", backgroundColor: "#BEC5DA"}}></div>

                    <div className='d-flex flex-column w-100 align-items-end'>
                        <div className='d-flex flex-row'>
                            <div className='me-3'>محل نمایش متن پیام</div>
                            <div><img className='rounded-circle' src={logo} alt="Logo" width={40} height={40}/></div>
                        </div>
                        <div className='text-secondary me-auto'>تاریخ پیام</div>
                    </div>

                    <div className='w-100 mt-2 mb-2' style={{height: "1px", backgroundColor: "#BEC5DA"}}></div>

                    <div className='mx-auto'>مشاهده همه</div>
                </div>
            </> : <></>}

            {shownotif ? <>
                <div id='showMsg' className='notifdiv'>show notifications</div>
            </> : <></>}


            <div id='childbg' style={{marginLeft: "1%"}}
                 onClick={() => setShowprof(showprof => !showprof) & setShownotif(false) & setShowmsg(false)}><img
                className='rounded-circle' src={logo} alt="Logo" width={40} height={40}/></div>
            <div><FontAwesomeIcon
                onClick={() => setShowmsg(showmsg => !showmsg) & setShownotif(false) & setShowprof(false)}
                style={{fontSize: "25", color: "#92979D"}} className='ms-4' id='msgicon' icon={faEnvelope}/></div>
            <div><FontAwesomeIcon
                onClick={() => setShownotif(shownotif => !shownotif) & setShowmsg(false) & setShowprof(false)}
                style={{fontSize: "25", color: "#418aeb"}} className='ms-4' icon={faBell}/></div>
            <div><FontAwesomeIcon onClick={() => setFull(full => !full)} style={{fontSize: "25", color: "#92979D"}}
                                  id="setnotfull" className='ms-4' icon={faExpand}/></div>
            <div className='ms-auto d-flex align-items-center' id='selectboxres' dir='rtl'>
                <select className='rounded-end border border-2 p-1 text-secondary' id='selectbox'>
                    <option>همه ی دسته ها</option>
                    <option value="1">پروژه های it</option>
                    <option value="2">موارد تجاری</option>
                    <option value="3">پروژه مایکروسافت</option>
                </select>
                <input type="text" id='resinput' className='p-1 px-5 border-0' style={{fontWeight: "600"}}
                       placeholder='جستجو'/>
                <button
                    className="font-weight-bold text-light align-middle rounded-start p-1 px-3" id='searchres'
                    style={{backgroundColor: "#92979D", border: "none"}}>
                    <FontAwesomeIcon style={{fontSize: "16", color: "white"}} icon={faMagnifyingGlass}/>
                </button>

            </div>
        </div>

    );

}

export default Topmenu;