import {
    ADD_CODEMELI,
    ADD_EMAIL,
    ADD_FIRSTNAME,
    ADD_ID,
    ADD_LASTNAME,
    ADD_PASSWORD,
    ADD_PASSWORDCHECK,
    ADD_PHONE,
    ADD_USER,
    ADD_USERNAME,
    SIDEBAR_BG,
    SIDEBAR_FONT,
    USER_IMG
} from "./dataTypes";

export const userimg = (e) => {
    return {
        type: USER_IMG,
        payload: e,
    }
}


export const sidebg = (e) => {
    return {
        type: SIDEBAR_BG,
        payload: e,
    }
}

export const sidefont = (e) => {
    return {
        type: SIDEBAR_FONT,
        payload: e,
    }
}


export const adduser = (event) => {
    return {
        type: ADD_USER,
        payload: event,
    }
}

export const addusername = (event) => {
    return {
        type: ADD_USERNAME,
        payload: event,
    };
};

export const addfirstname = (event) => {
    return {
        type: ADD_FIRSTNAME,
        payload: event,
    };
};
export const addlastname = (event) => {
    return {
        type: ADD_LASTNAME,
        payload: event,
    };
};
export const addid = (e) => {
    return {
        type: ADD_ID,
        payload: e,
    };
};

export const addphone = (e) => {
    return {
        type: ADD_PHONE,
        payload: e,
    };
};

export const addemail = (e) => {
    return {
        type: ADD_EMAIL,
        payload: e,
    };
};
export const addcodemeli = (e) => {
    return {
        type: ADD_CODEMELI,
        payload: e,
    };
};

export const addpassword = (event) => {
    return {
        type: ADD_PASSWORD,
        payload: event,
    };
};
export const addpasswordcheck = (e) => {
    return {
        type: ADD_PASSWORDCHECK,
        payload: e,
    };
};
