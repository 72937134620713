import React from 'react'
import './Map.css';
import $ from "jquery"


const InfClickedpos = (props) => {

    // {props.pos ? <>{props.pos.lat} <br/>{props.pos.lng}</>:<>nothing to show</>}

    return (
        <div>
            {props.pos === null ? <></> : <div id='containers'>
                <div className='d-flex flex-column justify-content-center align-items-start ms-2 font-weight-bold'>
                    <div><span id='span' style={{fontSize: "17px", fontWeight: "bold"}}>Lat </span> : {props.pos.lat}
                    </div>
                    <div><span id='span' style={{fontSize: "17px", fontWeight: "bold"}}>Lng </span> : {props.pos.lng}
                    </div>
                </div>
                <div className='d-flex flex-column justify-content-center align-items-end w-50'>
                    <button id='sendlocprev' onClick={() => {
                        $("#formcontainer").show()
                    }} style={{backgroundColor: "#418aeb"}} className='me-1 p-2 rounded'>ارسال مکان انتخاب شده
                    </button>
                </div>
            </div>}
        </div>
    )
}

export default InfClickedpos