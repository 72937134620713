import React, {useState} from 'react'
import FamilyForm from './familyForm/FamilyForm'
import GroundForm from './groundFrom/GroundForm'
import HomesForm from './homesForm/HomesForm'
import MainForm from './MainForm'

const Form = () => {
    const [tab, setTab] = useState(<MainForm/>)

    return (
        <div className='text-dark d-flex flex-column justify-content-center w-100 h-100 align-items-center '
             id='container' dir='rtl'>
            <div className='d-flex flex-row ms-auto h-100' id='radius'>
                <button onClick={() => {
                    setTab(<MainForm/>)
                }} className='p-2 rounded-end px-4'>اطلاعات سرپرست
                </button>
                <button onClick={() => {
                    setTab(<FamilyForm/>)
                }} className='p-2 px-4'>اطلاعات اشخاص خانوار
                </button>
                <button onClick={() => {
                    setTab(<GroundForm/>)
                }} className='p-2 px-4'>مشخصات زمین کشاورزی
                </button>
                <button onClick={() => {
                    setTab(<HomesForm/>)
                }} className='p-2 rounded-start px-4'>مشخصات املاک
                </button>

            </div>
            <div className='w-100' id='tab'>{tab}</div>
        </div>
    )
}

export default Form