import React, { useEffect, useState } from "react";
import "../../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft, faAnglesRight, faChevronLeft, faChevronRight, faEdit, faPlus, faUserMinus } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import Adduser from "./Adduser";
import Removeuser from "./Removeuser";
import EditUser from "./EditUser";
import { Link } from "react-router-dom";
const Table2 = (props) => {
  const [datas, setDatas] = useState([]);
  const [total, setTotal] = useState();
  const [id, setId] = useState();
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [deletearr, setDeletearr] = useState(0);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const axios = require("axios").default;
  axios.defaults.withCredentials = true;
  if (page === "") {
    setPage(1);
  }

  const nextpage = () => {
    setPage((p) => p + 1);
    // setPage(page - 1 + 2);
  };

  const prevpage = () => {
    setPage((p) => p - 1);
    // setPage(page - 1);
  };

  const refresh = () => {
    loaddata();
  };

  const data = {
    data_option: 1,
    page: page,
    rows: rows,
  };

  const loaddata = () => {
    axios.post("http://192.168.1.136/api/v01/api-select.php", data).then(function (response) {
      setTotal(10);

      console.log(response);
    });
  };
  const handleDelete = (e) => {
    setDeletearr(e);
  };

  const totalPage = Math.round(total / rows + 1);

  useEffect(() => {
    axios.post("192.168.1.136/api/v01/api-select.php?deleteArr=" + deletearr).then(function (response) {
      refresh();
    });
  }, [deletearr]);

  useEffect(() => {
    loaddata();
  }, [page, rows]);

  function show(e) {
    $(e).toggle(500);
  }

  //  const numbers = []
  //  for(let i=1;i<11;i++){
  //   numbers.push(i)
  //  }
  // console.log(numbers)

  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-start " style={{ marginTop: 80 }} dir="rtl">
      <div id="adduser" style={{ display: "none" }}>
        <Adduser refresh={refresh} show={show} />
      </div>
      <div id="removeRow" style={{ display: "none" }}>
        <Removeuser firstname={firstname} handleDelete={handleDelete} refresh={refresh} id={id} show={show} />
      </div>
      <div id="editRow" style={{ display: "none" }}>
        <EditUser firstname={firstname} lastname={lastname} phone={phone} email={email} refresh={refresh} id={id} show={show} />
      </div>

      <div className="w-100 rounded" id={props.id}>
        <div className="w-100 rounded">
          <div id="underline" className="text-dark my-2 py-2 w-100 mt-1">
            <span className="m-3 h3">نام تیبل(per-id)</span>
          </div>
          <div className="align-middle d-flex justify-content-start align-items-center">
            <Link to="/map" id="showdivadduser" className=" border rounded p-2 my-2 me-2" onClick={() => show("#adduser")}>
              <FontAwesomeIcon className="text-success mx-2 align-middle fa-lg" icon={faPlus} />
              افزودن خانوار
            </Link>
          </div>
          <div id="showhelp" style={{ display: "none" }}>
            <div>
              <FontAwesomeIcon className="text-danger" icon={faUserMinus} /> : پاک کردن کاربر (per-id)
            </div>
            <div>
              <FontAwesomeIcon className="text-warning" icon={faEdit} /> : ویرایش کاربر (per-id)
            </div>
          </div>
          <div className="w-100" id="tableRes" style={{ overflowY: "scroll", height: "540px" }}>
            <table className="table  border">
              <thead id="theadbg">
                <tr>
                  <th id="test" className="d-flex">
                    #
                  </th>
                  <th> شغل</th>
                  <th>نام سرپرست خانوار </th>
                  <th>آدرس</th>
                  <th> کد پستی</th>
                  <th> تعداد فرزندان</th>
                  <th>کد ملی</th>
                  <th>تلفن</th>
                  <th>status</th>

                  <th
                    onMouseEnter={() => {
                      document.getElementById("showhelp").style.display = "flex";
                    }}
                    onMouseLeave={() => {
                      document.getElementById("showhelp").style.display = "none";
                    }}
                  >
                    عمل ها (؟)
                  </th>
                </tr>
              </thead>

              <tbody>
                {/* {datas.map((data) => (
                  <tr className="align-middle" style={{ color: "#48494bd5" }} key={id}>
                    <td>{data.id}</td>
                    <td>{data.create_time}</td>
                    <td>{data.id_creator}</td>
                    <td>{data.id_modify}</td>
                    <td>{data.last_update_time}</td>
                    <td>{data.permission_groupe_access}</td>
                    <td>{data.username}</td>
                    <td>{data.status}</td>
                    <td className="align-middle">
                      <FontAwesomeIcon
                        id="showEdit"
                        className="text-warning h5"
                        onClick={() => setId(data.id) & setLastname(data.lastname) & setFirstname(data.firstname) & setEmail(data.email) & setPhone(data.phone) & show("#editRow")}
                        icon={faEdit}
                      />
                      <FontAwesomeIcon
                        id="showRemove"
                        className="h5 me-3"
                        style={{ color: "red" }}
                        onClick={() => setId(data.id) & setFirstname(data.firstname) & show("#removeRow")}
                        icon={faUserMinus}
                      />
                    </td>
                  </tr>
                ))} */}
                <tr>
                  <td>کشاورز</td>
                  <td> علی محمدی</td>
                  <td>چاله باغ</td>
                  <td>799645369</td>
                  <td>1</td>
                  <td>2110654982</td>
                  <td>935025884</td>
                  <td>Active</td>
                  <td>ویرایش</td>
                </tr>
                <tr>
                  <td>راننده</td>
                  <td> حسین محمدی </td>
                  <td> شهرداری</td>
                  <td>6546546546</td>
                  <td>۳</td>
                  <td>2110654982</td>
                  <td>935025884</td>
                  <td>Active</td>
                  <td>ویرایش</td>
                </tr>
                <tr>
                  <td>مغازه دار</td>
                  <td> حسن میرآییز </td>
                  <td> گرگانجدید</td>
                  <td>42141243</td>
                  <td>2</td>
                  <td>2110654982</td>
                  <td>935025884</td>
                  <td>Active</td>
                  <td>ویرایش</td>
                </tr>
                <tr>
                  <td>مهندس</td>
                  <td> کامران نجفی</td>
                  <td> پیروزی</td>
                  <td>5435435354</td>
                  <td>4</td>
                  <td>2110654982</td>
                  <td>935025884</td>
                  <td>Active</td>
                  <td>ویرایش</td>
                </tr>
                <tr>
                  <td>دکتر</td>
                  <td> حسین رحیمی </td>
                  <td> گرگان پارس</td>
                  <td>5436782</td>
                  <td>0</td>
                  <td>2110654982</td>
                  <td>935025884</td>
                  <td>Active</td>
                  <td>ویرایش</td>
                </tr>
                <tr>
                  <td>تعمیرکار</td>
                  <td> ناصر خاکزاد </td>
                  <td> صیاد</td>
                  <td>49878965</td>
                  <td>۳</td>
                  <td>2110654982</td>
                  <td>935025884</td>
                  <td>Active</td>
                  <td>ویرایش</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="d-flex j text-center w-100 flex-row" id="footertable">
            <div className="row justify-content-md-start w-50 rounded-bottom align-items-center align-middle" id="footerres" style={{ color: "#48494bd5" }}>
              <div className="col-1 me-3 p-1">
                <select
                  onChange={(e) => {
                    setRows(e.target.value);
                  }}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                </select>
              </div>

              <div className="col-1 me-4">
                <FontAwesomeIcon
                  className="fa-lg"
                  icon={faAnglesRight}
                  onClick={() => {
                    setPage(1);
                  }}
                />
              </div>

              <div className="col-1 me-2">
                {page < 2 ? <FontAwesomeIcon className="fa-lg text-danger" icon={faChevronRight} /> : <FontAwesomeIcon className="fa-lg text-success" icon={faChevronRight} onClick={prevpage} />}
              </div>

              <div className=" col-1">
                <div className="d-flex flex-row ms-1 w-100 text-center " style={{ borderRadius: "20px" }}>
                  صفحه
                  <input
                    style={{ width: "40px" }}
                    className="bg-dark text-light  text-center"
                    onChange={(e) => {
                      setPage(e.target.value);
                    }}
                    value={page}
                  />
                </div>
              </div>

              <div className="col-1 d-flex flex-row me-5">
                <div className="mx-2 justify-content-start d-flex">
                  <div>از</div>
                  <div>{totalPage}</div>
                </div>
              </div>

              <div className=" col-1">
                {total < page * rows ? (
                  <FontAwesomeIcon className="fa-lg text-danger" icon={faChevronLeft} />
                ) : (
                  <FontAwesomeIcon className="fa-lg text-success" icon={faChevronLeft} onClick={nextpage} />
                )}
              </div>

              <div className="col-1">
                <FontAwesomeIcon
                  className="fa-lg"
                  icon={faAnglesLeft}
                  onClick={() => {
                    setPage(totalPage);
                  }}
                />
              </div>
            </div>
            <div className="me-auto ms-4">کل خانوار ها : 6</div>
            {/*
            خط بالا 
            { {total }} */}
            <div className="me-auto d-flex flex-row ms-4">
              نمایش خانوار ها {page * rows - rows + 1} تا {total < page * rows ? <>{total}</> : <>{page * rows}</>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table2;
