import React, {useEffect, useState} from 'react'
import "./auth.css"
import axios from 'axios'
import {
    addcodemeli,
    addemail,
    addfirstname,
    addlastname,
    addpassword,
    addpasswordcheck,
    addphone,
    addusername
} from "../redux/dataActions"
import {useDispatch, useSelector} from 'react-redux'
import SignIn from './SignIn'

const SignUp = () => {

    const dispatch = useDispatch()
    const email = useSelector((state) => state.email)
    const password = useSelector((state) => state.password)
    const firstname = useSelector((state) => state.firstname)
    const lastname = useSelector((state) => state.lastname)
    const codemeli = useSelector((state) => state.codemeli)
    const phone = useSelector((state) => state.phone)
    const username = useSelector((state) => state.username)
    const passwordcheck = useSelector((state) => state.passwordcheck)

    const [emailerr, setEmailerr] = useState(null)
    const [phoneErr, setPhoneErr] = useState(null)
    const [passwordErr, setPasswordErr] = useState(null)
    const [buttoncheck, setButtoncheck] = useState(false)
    const [user, setUser] = useState(false)
    const [show, setShow] = useState(false)
    const [err, setErr] = useState(null)

    const signupurl = 'http://192.168.1.136/api/v01/api-signup.php';


    const data = {
        data_option: 'Create_user',
        firstname: firstname,
        username: username,
        lastname: lastname,
        mobile: phone,
        email: email,
        password: password,
        codemeli: codemeli
    }

    function createuser() {
        if (!firstname) {
            alert('نام  را وارد کنید')
        } else if (!lastname) {
            alert('نام خانوادگی را وارد کنید')
        } else if (!phone) {
            alert('شماره تماس را وارد کنید')
        } else if (phoneErr) {
            alert(phoneErr)
        } else if (emailerr) {
            alert(emailerr)
        } else if (!email) {
            alert('ایمیل را وارد کنید')
        } else if (!username) {
            alert('نام کاربری را وارد کنید')
        } else if (!password) {
            alert('پسوورد را وارد کنید')
        } else if (passwordErr) {
            alert(passwordErr)
        } else {
            axios.post(signupurl, data
            )
                .then(res => {
                    if (res.data.status === 201) {
                        setUser(true)

                    } else if (res.data.error_message) {

                        setErr(res.data.error_message)
                    } else {
                        <></>
                    }
                }).catch(err => console.log(err))
        }

    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    useEffect(() => {
        user ? window.location = "/signup" : <></>
    }, [user])

    useEffect(() => {
        if (!(phone.match('[0-9]{11}'))) {
            setPhoneErr('شماره همراه صحیح نیست')
        } else {
            setPhoneErr(null)
        }
    }, [phone])

    useEffect(() => {
        if (validateEmail(email)) {
            setEmailerr(null)
        } else {
            setEmailerr('ایمیل صحیح نیست')
        }
    }, [email])

    useEffect(() => {
        password === passwordcheck ? setPasswordErr(null) : setPasswordErr('پسوورد یکسان نیست')
    }, [password, passwordcheck])

    return (

        <div id='signup'>
            {show ?
                <div id='shadow'
                     className='d-flex w-40 bg-light flex-column m-auto justify-content-center align-items-end p-3 rounded'>
                    <div style={{fontSize: '20px', fontWeight: '600'}}>
                        ایجاد اکانت
                    </div>
                    <div className='mx-auto my-2' style={{color: 'rgb(247, 64, 64)'}}>{err}</div>
                    <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                        <div className='d-flex flex-column w-40 mx-3'>
                            <label className='mt-3'>نام </label>
                            <input onChange={(e) => dispatch(addfirstname(e.target.value))}
                                   className='form-control w-100 mt-2 text-end'/>
                        </div>
                        <div className='d-flex mx-3 flex-column w-40'>
                            <label className='mt-3'>نام خانوادگی </label>
                            <input onChange={(e) => dispatch(addlastname(e.target.value))}
                                   className='form-control w-100 mt-2 text-end'/>
                        </div>
                    </div>

                    <div className='d-flex flex-row justify-content-end align-items-center w-100'>
                        <div className='d-flex flex-column w-40 mx-3'>
                            <label className='mt-3 d-flex flex-row w-100'>
                                {phoneErr === null ? <></> : <div className='' style={{
                                    color: 'red',
                                    fontSize: '12px',
                                    fontWeight: '700',
                                    whiteSpace: 'nowrap'
                                }}> {phoneErr}</div>}
                                <div className='ms-auto text-nowrap'>شماره تماس</div>
                            </label>
                            <input onChange={(e) => dispatch(addphone(e.target.value))}
                                   className='form-control w-100 mt-2 text-end' placeholder='09... '/>
                        </div>
                        <div className='d-flex flex-column w-40 mx-3'>
                            <label className='mt-3 d-flex flex-row w-100'>
                                {emailerr === null ? <></> : <div className='me-5' style={{
                                    color: 'red',
                                    fontSize: '12px',
                                    fontWeight: '700'
                                }}> {emailerr}</div>}
                                <div className='ms-auto '>ایمیل</div>
                            </label>
                            <input onChange={(e) => dispatch(addemail(e.target.value))} id='email'
                                   className='form-control w-100 mt-2 text-end' placeholder='ایمیل'/>
                        </div>
                    </div>

                    <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                        <div className='d-flex flex-column w-40 mx-3'>
                            <label className='mt-3'>نام کاربری</label>
                            <input onChange={(e) => dispatch(addusername(e.target.value.trim()))} value={username}
                                   className='form-control w-100 mt-2 text-end' placeholder='نام کاربری'/>
                        </div>
                        <div className='d-flex flex-column w-40 mx-3'>
                            <label className='mt-3'> کد ملی</label>
                            <input onChange={(e) => dispatch(addcodemeli(e.target.value.trim()))} value={codemeli}
                                   className='form-control w-100 mt-2 text-end' placeholder=' کد ملی'/>
                        </div>
                    </div>


                    <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                        <div className='d-flex flex-column w-40 mx-3'>
                            <label className='mt-3'>پسوورد</label>
                            <input onChange={(e) => dispatch(addpassword(e.target.value))}
                                   className='form-control w-100 mt-2 text-end' type='password' placeholder='پسوورد'/>
                        </div>
                        <div className='d-flex flex-column w-40 mx-3'>
                            <label className='mt-3 d-flex flex-row w-75'>
                                <div className='ms-auto text-nowrap'>تکرار پسوورد</div>
                                {passwordErr === null ? <></> : <div className='me-5 text-nowrap' style={{
                                    color: 'red',
                                    fontSize: '12px',
                                    fontWeight: '700'
                                }}> {passwordErr}</div>}
                            </label>
                            <input onChange={(e) => dispatch(addpasswordcheck(e.target.value))}
                                   className='form-control w-100 mt-2 text-end' type='password' placeholder='پسوورد'/>
                        </div>
                    </div>


                    <div className='d-flex flex-row justify-content-between w-100'>
                        <button id='button' disabled={buttoncheck ? true : false} onClick={createuser}> ساخت اکانت
                        </button>
                        <button id='elsebuttton' onClick={() => setShow(show => !show)}>ورود</button>
                    </div>
                </div>
                :
                <SignIn show={show} setShow={setShow}/>}

        </div>
    )
}

export default SignUp