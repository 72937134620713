import React, {useState} from 'react'
import {faCircleXmark} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import "../../auth/auth.css"
import {useDispatch} from 'react-redux'

const Adduser = (props) => {
    
    const [show, setShow] = useState(false)
    const [area,setArea]=useState(null)
    const [phone,setPhone]=useState(null)
    const [zipcode,setZipcode]=useState(null)
    const [createddate,setCreateddate]=useState(null)
    const [materialtype,setMaterialType]=useState(null)
    const [licencenumber,setLicencenumber]=useState(null)
    const [arealicence,setArealicence]=useState(null)
    const [worktype,setWorktype]=useState(null)
    const [info,setInfo]=useState(null)
    const [createddatelicence,setCreateddatelicence]=useState(null)

    const [havephone,setHavephone]=useState(false)
    const [water,setWater]=useState(false)
    const [power,setPower]=useState(false)
    const [gas,setGas]=useState(false)
    const [licence,setLicence]=useState(false)
   
    const[partner1,setPartner1]=useState(null)
    const[nationalcodepartner1,setNationalcodepartner1]=useState(null)
    const[sharepartner1,setSharepartner1]=useState(null)
    const[partner2,setPartner2]=useState(null)
    const[nationalcodepartner2,setNationalcodepartner2]=useState(null)
    const[sharepartner2,setSharepartner2]=useState(null)
    const[partner3,setPartner3]=useState(null)
    const[nationalcodepartner3,setNationalcodepartner3]=useState(null)
    const[sharepartner3,setSharepartner3]=useState(null)
    const[partner4,setPartner4]=useState(null)
    const[nationalcodepartner4,setNationalcodepartner4]=useState(null)
    const[sharepartner4,setSharepartner4]=useState(null)
    const[partner5,setPartner5]=useState(null)
    const[nationalcodepartner5,setNationalcodepartner5]=useState(null)
    const[sharepartner5,setSharepartner5]=useState(null)
    const[partner6,setPartner6]=useState(null)
    const[nationalcodepartner6,setNationalcodepartner6]=useState(null)
    const[sharepartner6,setSharepartner6]=useState(null)


    const data={
        data_option:'مشخصات املاک',
        area: area,
        phone:phone ,
        zipcode: zipcode,
        createddate:createddate ,
        materialtype: materialtype,
        licencenumber: licencenumber,
        arealicence: arealicence,
        worktype: worktype,
        info: info,
        createddatelicence:createddatelicence ,
        havephone: havephone,
        water:water ,
        power:power ,
        gas:gas ,
        licence:licence ,
        partner1:partner1 ,
        nationalcodepartner1: nationalcodepartner1,
        sharepartner1: sharepartner1,
        partner2:partner2 ,
        nationalcodepartner2: nationalcodepartner2,
        sharepartner2:sharepartner2 ,
        partner3: partner3,
        nationalcodepartner3:nationalcodepartner3 ,
        sharepartner3:sharepartner3 ,
        partner4: partner4,
        nationalcodepartner4: nationalcodepartner4,
        sharepartner4:sharepartner4 ,
        partner5: partner5,
        nationalcodepartner5:nationalcodepartner5 ,
        sharepartner5:sharepartner5 ,
        partner6:partner6 ,
        nationalcodepartner6: nationalcodepartner6,
        sharepartner6: sharepartner6,
    }



    return (
        <form id='shadow'
             className='d-flex w-80 ms-5 bg-light flex-column m-auto justify-content-center align-items-end p-3 rounded'>
            <FontAwesomeIcon id='close2' style={{color: 'blue'}} className='mx-2 align-middle fa-lg'
                             onClick={() => props.show("#adduser3")} icon={faCircleXmark}/>
            <div style={{fontSize: '20px', fontWeight: '600'}} className="ms-auto">
                افزودن ملک
            </div>
            <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                <div className='d-flex flex-column w-20 mx-3'>
                    <label className='mt-2'>مساحت بنا </label>
                    <input className='form-control w-100 mt-2 text-end'
                    onChange={(e)=>setArea(e.target.value)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-2'> تلفن </label>
                    <input className='form-control w-100 mt-2 text-end'
                    onChange={(e)=>setPhone(e.target.value)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-2'> کدپستی </label>
                    <input className='form-control w-100 mt-2 text-end'
                    onChange={(e)=>setZipcode(e.target.value)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-2'> تاریخ احداث بنا </label>
                    <input className='form-control w-100 mt-2 text-end'
                    onChange={(e)=>setCreateddate(e.target.value)}/>
                </div>
                <div className='d-flex flex-column w-20 mx-3'>
                    <label className='mt-2'> نوع مصالح </label>
                    <input className='form-control w-100 mt-2 text-end'
                    onChange={(e)=>setMaterialType(e.target.value)}/>
                </div>

            </div>
            <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                <div className='d-flex flex-column w-20 mx-3'>
                    <label className='mt-2'>شماره پروانه </label>
                    <input className='form-control w-100 mt-2 text-end'
                    onChange={(e)=>setLicencenumber(e.target.value)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-2'> مساحت مندرج در پروانه </label>
                    <input className='form-control w-100 mt-2 text-end'
                    onChange={(e)=>setArealicence(e.target.value)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-2'> نوع کار </label>
                    <input className='form-control w-100 mt-2 text-end'
                    onChange={(e)=>setWorktype(e.target.value)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-2'> توضیحات </label>
                    <input className='form-control w-100 mt-2 text-end'
                    onChange={(e)=>setInfo(e.target.value)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-2'>تاریخ احداث در پروانه</label>
                    <input type='date' className='form-control w-100 mt-2 text-end'
                    onChange={(e)=>setCreateddatelicence(e.target.value)}/>
                </div>


            </div>

            <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>

                <div className='d-flex flex-column w-10 mx-3 form-switch'>
                    <label className='mt-2'>تلفن </label>
                    <input type='checkbox' className='mt-2 text-end form-control mx-auto form-check-input '
                    onChange={(e)=>setHavephone(current=>!current)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-10 form-switch'>
                    <label className='mt-2'>آب </label>
                    <input type='checkbox' className='mt-2 text-end form-control mx-auto form-check-input '
                    onChange={(e)=>setWater(current=>!current)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-10 form-switch'>
                    <label className='mt-2'>برق </label>
                    <input type='checkbox' className='mt-2 text-end form-control mx-auto form-check-input '
                    onChange={(e)=>setPower(current=>!current)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-10 form-switch'>
                    <label className='mt-2'> گاز </label>
                    <input type='checkbox' className='mt-2 text-end form-control mx-auto form-check-input '
                    onChange={(e)=>setGas(current=>!current)}/>
                </div>
                <div className='d-flex flex-column w-10 mx-3 form-switch'>
                    <label className='mt-2'>پروانه ساخت </label>
                    <input type='checkbox' className='mt-2 text-end form-control mx-auto form-check-input '
                    onChange={(e)=>setLicence(current=>!current)}/>
                </div>

            </div>

            <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                <div className='d-flex flex-column w-20 mx-3 form-switch '>
                    <label className='mt-2'> ملک مشاعی </label>
                    <input type='checkbox' onChange={(e) => setShow(current => !current)}
                           className='form-control form-check-input mx-auto mt-2 text-end' id="flexSwitchCheckChecked"/>
                </div>
            </div>
            {show ?
                <div className='d-flex w-100 flex-column'>
                    <div className='d-flex flex-row justify-content-start align-items-center w-100 ' dir='rtl'>
                        <div className='d-flex flex-column w-20 mx-3'>
                            <label className='mt-2'>نام مالک 1 </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setPartner1(e.target.value)}/>
                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-2'> شماره ملی </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setNationalcodepartner1(e.target.value)}/>
                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-2'> میزان سهم </label>
                            <input className='form-control w-100 mt-2 text-end'
                             onChange={(e)=>setSharepartner1(e.target.value)}/>
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                        <div className='d-flex flex-column w-20 mx-3'>
                            <label className='mt-2'>نام مالک 2 </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setPartner2(e.target.value)}/>
                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-2'> شماره ملی </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setNationalcodepartner2(e.target.value)}/>
                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-2'> میزان سهم </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setSharepartner2(e.target.value)}/>
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                        <div className='d-flex flex-column w-20 mx-3'>
                            <label className='mt-2'>نام مالک 3 </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setPartner3(e.target.value)}/>
                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-2'> شماره ملی </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setNationalcodepartner3(e.target.value)}/>
                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-2'> میزان سهم </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setSharepartner3(e.target.value)}/>
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                        <div className='d-flex flex-column w-20 mx-3'>
                            <label className='mt-2'>نام مالک 4 </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setPartner4(e.target.value)}/>
                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-2'> شماره ملی </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setNationalcodepartner4(e.target.value)}/>
                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-2'> میزان سهم </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setSharepartner4(e.target.value)}/>
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                        <div className='d-flex flex-column w-20 mx-3'>
                            <label className='mt-2'>نام مالک 5 </label>
                            <input className='form-control w-100 mt-2 text-end'
                             onChange={(e)=>setPartner5(e.target.value)}/>

                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-2'> شماره ملی </label>
                            <input className='form-control w-100 mt-2 text-end'
                             onChange={(e)=>setNationalcodepartner5(e.target.value)}/>

                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-2'> میزان سهم </label>
                            <input className='form-control w-100 mt-2 text-end'
                             onChange={(e)=>setSharepartner5(e.target.value)}/>
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                        <div className='d-flex flex-column w-20 mx-3'>
                            <label className='mt-2'>نام مالک 6 </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setPartner6(e.target.value)}/>
                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-2'> شماره ملی </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setNationalcodepartner6(e.target.value)}/>
                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-2'> میزان سهم </label>
                            <input className='form-control w-100 mt-2 text-end'
                                 onChange={(e)=>setSharepartner6(e.target.value)}/>
                        </div>
                    </div>
                </div>

                : <></>}


            <div className='d-flex flex-row justify-content-between w-100'>
                <input id='button' type='submit' className='me-auto text-light' value='افزودن ملک'/>
            </div>
        </form>
    )
}

export default Adduser