import React, {useEffect, useState} from 'react'
import {faCircleXmark} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import $ from "jquery";
import {addcodemeli, addemail, addfirstname, addlastname, addphone, addusername} from "../../redux/dataActions"
import {useDispatch, useSelector} from 'react-redux'

const EditUser = (props) => {

    const axios = require('axios').default;
    const dispatch = useDispatch()
    const email = useSelector((state) => state.email)
    const password = useSelector((state) => state.password)
    const firstname = useSelector((state) => state.firstname)
    const lastname = useSelector((state) => state.lastname)
    const codemeli = useSelector((state) => state.codemeli)
    const phone = useSelector((state) => state.phone)
    const username = useSelector((state) => state.username)
    const [emailerr, setEmailerr] = useState(null)
    const [phoneErr, setPhoneErr] = useState(null)
    const [passwordErr, setPasswordErr] = useState(null)


    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };


    useEffect(() => {
        if (!(phone.match('[0-9]{11}'))) {
            setPhoneErr('شماره همراه صحیح نیست')
        } else {
            setPhoneErr(null)
        }
    }, [phone])

    useEffect(() => {
        if (validateEmail(email)) {
            setEmailerr(null)
        } else {
            setEmailerr('ایمیل صحیح نیست')
        }
    }, [email])


    function useStateWithDep(defaultValue) {
        const [value, setValue] = useState(defaultValue);

        useEffect(() => {
            setValue(defaultValue);
        }, [defaultValue]);
        return [value, setValue];
    }

    const editid = props.id

    const edituser = () => {
        axios.post('http://192.168.1.139/api/arrays.php?editfirstname=' + `"${firstname}"` + '&editlastname=' + `"${lastname}"` + '&editphone=' + `"${phone}"` + '&editemail=' + `"${email}"` + '&editid=' + editid)
            .then(function () {
                props.refresh()
                props.show("#editRow")
                $(document).ready(function () {
                    $('input').val("");
                });
            })
    }

    return (

        <div id='shadow'
             className='d-flex w-40 bg-light flex-column m-auto justify-content-center align-items-end p-3 rounded'>
            <FontAwesomeIcon id='close' style={{color: 'blue'}} className='mx-2 align-middle fa-lg'
                             onClick={() => props.show("#editRow")} icon={faCircleXmark}/>
            <div style={{fontSize: '20px', fontWeight: '600'}} className="ms-auto">
                ویرایش کاربر {props.firstname}
            </div>
            <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                <div className='d-flex flex-column w-40 mx-3'>
                    <label className='mt-3'>نام </label>
                    <input onChange={(e) => dispatch(addfirstname(e.target.value))} placeholder={props.firstname}
                           className='form-control w-100 mt-2 text-end'/>
                </div>
                <div className='d-flex mx-3 flex-column w-40'>
                    <label className='mt-3'>نام خانوادگی </label>
                    <input onChange={(e) => dispatch(addlastname(e.target.value))} placeholder={props.lastname}
                           className='form-control w-100 mt-2 text-end'/>
                </div>
            </div>

            <div className='d-flex flex-row justify-content-end align-items-center w-100' dir='ltr'>
                <div className='d-flex flex-column w-40 mx-3'>
                    <label className='mt-3 d-flex flex-row w-100'>
                        {phoneErr === null ? <></> : <div className='' style={{
                            color: 'red',
                            fontSize: '12px',
                            fontWeight: '700',
                            whiteSpace: 'nowrap'
                        }}> {phoneErr}</div>}
                        <div className='ms-auto text-nowrap'>شماره تماس</div>
                    </label>
                    <input onChange={(e) => dispatch(addphone(e.target.value))}
                           className='form-control w-100 mt-2 text-end' placeholder={props.phone}/>
                </div>
                <div className='d-flex flex-column w-40 mx-3'>
                    <label className='mt-3 d-flex flex-row w-100'>
                        {emailerr === null ? <></> : <div className='me-5' style={{
                            color: 'red',
                            fontSize: '12px',
                            fontWeight: '700'
                        }}> {emailerr}</div>}
                        <div className='ms-auto '>ایمیل</div>
                    </label>
                    <input onChange={(e) => dispatch(addemail(e.target.value))} id='email'
                           className='form-control w-100 mt-2 text-end' placeholder={props.email}/>
                </div>
            </div>

            <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                <div className='d-flex flex-column w-40 mx-3'>
                    <label className='mt-3'>نام کاربری</label>
                    <input onChange={(e) => dispatch(addusername(e.target.value.trim()))} value={username}
                           className='form-control w-100 mt-2 text-end' placeholder='نام کاربری'/>
                </div>
                <div className='d-flex flex-column w-40 mx-3'>
                    <label className='mt-3'> کد ملی</label>
                    <input onChange={(e) => dispatch(addcodemeli(e.target.value.trim()))} value={codemeli}
                           className='form-control w-100 mt-2 text-end' placeholder=' کد ملی'/>
                </div>
            </div>


            <div className='d-flex flex-row justify-content-between w-100'>
                <button id='button' className='me-auto' onClick={edituser}> ثبت تغییرات</button>
            </div>
        </div>
    )
}

export default EditUser