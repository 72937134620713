import React from 'react'
import Table from '../Table/Table'

const Manageusers = () => {
    return (
        <div className='bg-light'>
            <Table/>
        </div>
    )
}

export default Manageusers