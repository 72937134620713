import React, {useEffect, useState ,useRef,} from "react";
import {MapContainer, TileLayer, useMap,FeatureGroup} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { EditControl } from "react-leaflet-draw";
import { GeometryUtil } from "leaflet";
import './Map.css';
import Select from 'react-select'
import InfClickedpos from "./InfClickedpos";
import Routing from "./Routing";
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css"
import LeafletgeoSearch from "./Search";



export default function Markedmap() {

    const [i ,setI]=useState(0)
    const [position, setPosition] = useState(null);
    const [polColor,setPolColor] = useState('red');
    const [test2,setTest2]= useState([])
    const [startRlat,setStartRlat] = useState(36.841795837611464)
    const [startRlng,setStartRlng] = useState(54.43292480706078)
    const [endRlat,setEndRlat]= useState(36.81713)
    const [endRlng,setEndRlng]= useState(54.452244758)

   

function ClickPos() {

    const map = useMap();
    useEffect(() => {
        map.addEventListener("click", function (e) {
         setEndRlat(e.latlng.lat)
         setEndRlng(e.latlng.lng)
          
            
        })
    }, [map]);
return null

}


useEffect(() => {
 test2.length < 1? <></>: test2[i].setStyle({fillColor:`${polColor}`,weight:2,color:'#4276dd'})
}, [polColor])


const _onCreate =e =>{
    // e.layer._latlngs[0]
    setTest2(test2=>[...test2,e.layer])
    
    
 
    console.log(GeometryUtil.geodesicArea(e.layer._latlngs[0]))
    e.layer.setStyle({fillColor:`${polColor}`,weight:2,color:'black'})   

}
const _onDeleted =e =>{
    console.log(e)
}
const _onEdited =e =>{
  console.log(e)
}

const rows =[];

for (let i = 0; i < test2.length  ; i++) {
    rows.push({value: i ,label: 'box' + `${i+1}`})
}

 

    return (
        <div id='map'>
              <InfClickedpos pos={position}/>
               <input type='color'  id="selectBox"  onChange={(e)=>{setPolColor(e.target.value)}}/>
              <Select id="selectcolor" options={rows} onChange={(e)=>{setI(e.value)}}/>
            <MapContainer
                id="ok"
                center={[36.8409, 54.4274]}
                zoom={18}
                scrollWheelZoom
                style={{height: "100vh", width: "100vw"}}
            >
                <TileLayer

                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />  
                     
                     <FeatureGroup>
                        <EditControl position="topleft" onCreated={_onCreate} onEdited={_onEdited} onDeleted={_onDeleted}
                        draw={{
                            rectangle:true,
                            polyline:false,
                            circle:false,
                            circlemarker:false,
                            marker:false
                        }}
                        edit={{
                            selectedPathOptions: {
                              // this property should be one level up
                              color: 'red',
                              fillColor: 'black',
                            },
                            shapeOptions: { color: polColor },
                          }}
                        />
                            <LeafletgeoSearch />
                <Routing endlat={endRlat} startlat={startRlat} endlng={endRlng} startlng={startRlng}/>
                    
                     </FeatureGroup>
                     <ClickPos/>
            </MapContainer>

        </div>
    );
}
