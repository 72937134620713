import React, {useState} from 'react'
import {faCircleXmark} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import "../../auth/auth.css"
import {useDispatch} from 'react-redux'

const Adduser = (props) => {
    
    const [show, setShow] = useState(false)

    const[area,setArea]=useState(null)
    const[usertype,setUsertype]=useState(null)
    const[groundtype,setGroundtype]=useState(null)
    const[north,setNorth]=useState(null)
    const[south,setSouth]=useState(null)
    const[east,setEast]=useState(null)
    const[west,setWest]=useState(null)
    const[welldepth,setWelldepth]=useState(null)
    const[welldate,setWelldate]=useState(null)
    const[licensenumber,setLicencenumber]=useState(null)
    const[welldatelicence,setWelldatelicence]=useState(null)
    const[welldepthlicence,setWelldepthlicence]=useState(null)

    const[active,setActive]=useState(false)
    const[document,setDocument]=useState(false)
    const[licence,setLicence]=useState(false)

    const[partner1,setPartner1]=useState(null)
    const[nationalcodepartner1,setNationalcodepartner1]=useState(null)
    const[sharepartner1,setSharepartner1]=useState(null)
    const[partner2,setPartner2]=useState(null)
    const[nationalcodepartner2,setNationalcodepartner2]=useState(null)
    const[sharepartner2,setSharepartner2]=useState(null)
    const[partner3,setPartner3]=useState(null)
    const[nationalcodepartner3,setNationalcodepartner3]=useState(null)
    const[sharepartner3,setSharepartner3]=useState(null)
    const[partner4,setPartner4]=useState(null)
    const[nationalcodepartner4,setNationalcodepartner4]=useState(null)
    const[sharepartner4,setSharepartner4]=useState(null)
    const[partner5,setPartner5]=useState(null)
    const[nationalcodepartner5,setNationalcodepartner5]=useState(null)
    const[sharepartner5,setSharepartner5]=useState(null)
    const[partner6,setPartner6]=useState(null)
    const[nationalcodepartner6,setNationalcodepartner6]=useState(null)
    const[sharepartner6,setSharepartner6]=useState(null)

    const data={
        data_option:'مشخصات زمین کشاورزی',
        area:area,
        usertype:usertype ,
        groundtype: groundtype,
        north:north ,
        south:south ,
        east: east,
        west:west ,
        welldepth:welldepth ,
        welldate:welldate ,
        licensenumber: licensenumber,
        welldatelicence: welldatelicence,
        welldepthlicence: welldepthlicence,
        active: active,
        document:document ,
        licence: licence,
        partner1:partner1 ,
        nationalcodepartner1: nationalcodepartner1,
        sharepartner1: sharepartner1,
        partner2:partner2 ,
        nationalcodepartner2: nationalcodepartner2,
        sharepartner2:sharepartner2 ,
        partner3: partner3,
        nationalcodepartner3:nationalcodepartner3 ,
        sharepartner3:sharepartner3 ,
        partner4: partner4,
        nationalcodepartner4: nationalcodepartner4,
        sharepartner4:sharepartner4 ,
        partner5: partner5,
        nationalcodepartner5:nationalcodepartner5 ,
        sharepartner5:sharepartner5 ,
        partner6:partner6 ,
        nationalcodepartner6: nationalcodepartner6,
        sharepartner6: sharepartner6,
    }

    return (
        <div id='shadow'
             className='d-flex w-80 ms-5 bg-light flex-column m-auto justify-content-center align-items-end p-3 rounded'>
            <FontAwesomeIcon id='close2' style={{color: 'blue'}} className='mx-2 align-middle fa-lg'
                             onClick={() => props.show("#adduser2")} icon={faCircleXmark}/>
            <div style={{fontSize: '20px', fontWeight: '600'}} className="ms-auto">
                افزودن زمین
            </div>
            <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                <div className='d-flex flex-column w-20 mx-3'>
                    <label className='mt-1'>مساحت </label>
                    <input className='form-control w-100 mt-2 text-end'
                    onChange={(e)=>setArea(e.target.value)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-1'>نوع کاربری </label>
                    <input className='form-control w-100 mt-2 text-end'
                     onChange={(e)=>setUsertype(e.target.value)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-1'> نوع زمین(آبی/باغی/...) </label>
                    <input className='form-control w-100 mt-2 text-end'
                     onChange={(e)=>setGroundtype(e.target.value)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-1'> شمالا </label>
                    <input className='form-control w-100 mt-2 text-end'
                     onChange={(e)=>setNorth(e.target.value)}/>
                </div>
                <div className='d-flex flex-column w-20 mx-3'>
                    <label className='mt-1'> جنوبا </label>
                    <input className='form-control w-100 mt-2 text-end'
                     onChange={(e)=>setSouth(e.target.value)}/>
                </div>

            </div>
            <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                <div className='d-flex flex-column w-20 mx-3'>
                    <label className='mt-1'>شرقا </label>
                    <input className='form-control w-100 mt-2 text-end'
                     onChange={(e)=>setEast(e.target.value)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-1'> غربا </label>
                    <input className='form-control w-100 mt-2 text-end'
                     onChange={(e)=>setWest(e.target.value)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-1'>اطلاعات چاه (عمق چاه)</label>
                    <input className='form-control w-100 mt-2 text-end'
                     onChange={(e)=>setWelldepth(e.target.value)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-1'> تاریخ حفر چاه </label>
                    <input type='date' className='form-control w-100 mt-2 text-end'
                     onChange={(e)=>setWelldate(e.target.value)}/>
                </div>
                <div className='d-flex flex-column w-20 mx-3 form-switch'>
                    <label className='mt-1'> فعال/غیرفعال </label>
                    <input type='radio' className='form-control form-check-input mx-auto mt-2 text-end'
                     onChange={(e)=>setActive(current=>!current)}/>
                </div>

            </div>

            <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                <div className='d-flex flex-column w-20 mx-3 form-switch'>
                    <label className='mt-1'> پروانه </label>
                    <input type='radio' className='form-control form-check-input mx-auto mt-2 text-end'
                     onChange={(e)=>setLicence(current=>!current)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-1'> شماره پروانه </label>
                    <input className='form-control w-100 mt-2 text-end'
                     onChange={(e)=>setLicencenumber(e.target.value)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-1'>عمق ثبت شده در پروانه</label>
                    <input className='form-control w-100 mt-2 text-end'
                    onChange={(e)=>setWelldepthlicence(e.target.value)}
                     />
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-1'> تاریخ حفر چاه در پروانه</label>
                    <input type='date' className='form-control w-100 mt-2 text-end'
                    onChange={(e)=>setWelldatelicence(e.target.value)}/>
                </div>
                <div className='d-flex flex-column w-20 mx-3 form-switch'>
                    <label className='mt-1'> سند(رسمی) </label>
                    <input type='radio' className='form-control form-check-input mx-auto mt-2 text-end'
                        onChange={(e)=>setDocument(current=>!current)}/>
                </div>

            </div>

            <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                <div className='d-flex flex-column w-20 mx-3 form-switch '>
                    <label className='mt-1'> زمین مشاعی </label>
                    <input type='checkbox' onChange={(e) => setShow(current => !current)}
                           className='form-control form-check-input mx-auto mt-2 text-end' id="flexSwitchCheckChecked"/>
                </div>
            </div>
            {show ?
                <div className='d-flex w-100 flex-column'>
                    <div className='d-flex flex-row justify-content-start align-items-center w-100 ' dir='rtl'>
                        <div className='d-flex flex-column w-20 mx-3'>
                            <label className='mt-1'>نام مالک 1 </label>
                            <input className='form-control w-100 mt-2 text-end'
                                onChange={(e)=>setPartner1(e.target.value)}/>
                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-1'> شماره ملی </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setNationalcodepartner1(e.target.value)}/>
                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-1'> میزان سهم </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setSharepartner1(e.target.value)}/>
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                        <div className='d-flex flex-column w-20 mx-3'>
                            <label className='mt-1'>نام مالک 2 </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setPartner2(e.target.value)}/>
                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-1'> شماره ملی </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setNationalcodepartner2(e.target.value)}/>
                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-1'> میزان سهم </label>
                            <input className='form-control w-100 mt-2 text-end'
                              onChange={(e)=>setSharepartner2(e.target.value)}/>
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                        <div className='d-flex flex-column w-20 mx-3'>
                            <label className='mt-1'>نام مالک 3 </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setPartner3(e.target.value)}/>
                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-1'> شماره ملی </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setNationalcodepartner3(e.target.value)}/>
                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-1'> میزان سهم </label>
                            <input className='form-control w-100 mt-2 text-end'
                              onChange={(e)=>setSharepartner3(e.target.value)}/>
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                        <div className='d-flex flex-column w-20 mx-3'>
                            <label className='mt-1'>نام مالک 4 </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setPartner4(e.target.value)}/>
                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-1'> شماره ملی </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setNationalcodepartner4(e.target.value)}/>
                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-1'> میزان سهم </label>
                            <input className='form-control w-100 mt-2 text-end'
                              onChange={(e)=>setSharepartner4(e.target.value)}/>
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                        <div className='d-flex flex-column w-20 mx-3'>
                            <label className='mt-1'>نام مالک 5 </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setPartner5(e.target.value)}/>
                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-1'> شماره ملی </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setNationalcodepartner5(e.target.value)}/>
                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-1'> میزان سهم </label>
                            <input className='form-control w-100 mt-2 text-end'
                              onChange={(e)=>setSharepartner5(e.target.value)}/>
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                        <div className='d-flex flex-column w-20 mx-3'>
                            <label className='mt-1'>نام مالک 6 </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setPartner6(e.target.value)}/>
                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-1'> شماره ملی </label>
                            <input className='form-control w-100 mt-2 text-end'
                            onChange={(e)=>setNationalcodepartner6(e.target.value)}/>
                        </div>
                        <div className='d-flex mx-3 flex-column w-20'>
                            <label className='mt-1'> میزان سهم </label>
                            <input className='form-control w-100 mt-2 text-end'
                              onChange={(e)=>setSharepartner6(e.target.value)}/>
                        </div>
                    </div>
                </div>

                : <></>}


            <div className='d-flex flex-row justify-content-between w-100'>
                <button id='button' className='me-auto'> افزودن اکانت</button>
            </div>
        </div>
    )
}

export default Adduser