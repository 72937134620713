import React, { useEffect } from "react";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircleXmark, faX } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
const Form = (props) => {
  useEffect(() => {
    $("#formcontainer").hide();
  }, []);

  function clear(e) {
    $(e).hide(500);
  }

  return (
    <div id="formcontainer">
      <div id="maincontainer" className="d-flex flex-column justify-content-start align-items-start p-4">
        <div className="d-flex flex-row w-100">
          <div className="d-flex  flex-row h5 font-weight-bold ms-auto">ارسال فرم</div>
          <FontAwesomeIcon className="ms-auto fa-lg" style={{ color: "#418aeb" }} onClick={() => clear("#formcontainer")} icon={faCircleXmark} />
        </div>

        <div className="w-100 border mt-3"></div>

        <div className="my-3 d-flex flex-column w-100" style={{ fontSize: "15px" }}>
          <div className="mx-auto"> : کد پستی</div>
          <input className="mt-2 rounded text-end" id="input" placeholder=" کد پستی"></input>
        </div>

        <div className=" d-flex flex-column w-100" style={{ fontSize: "15px" }}>
          <div className="mx-auto"> : انتخاب تصاویر</div>
          <input className="mt-2 rounded text-end" id="input" type="file"></input>
        </div>

        <div className="my-3 d-flex flex-column w-100" style={{ fontSize: "15px" }}>
          <div className="mx-auto"> : موقعیت مکانی</div>
          {props.pos === null ? null : (
            <div className="w-100 py-1 mx-auto text-center mt-3 rounded text-light" style={{ backgroundColor: "#418aeb" }}>
              <span>Lat : </span>
              {props.pos.lat} <br /> <span>Lng : </span>
              {props.pos.lng}
            </div>
          )}
        </div>

        <div className="d-flex flex-row w-100 justify-content-around">
          <Link to="/form" className="bg-light text-success border rounded">
            <FontAwesomeIcon icon={faCheck} className="me-1 p-1 align-middle" />
            ارسال
          </Link>
          <button
            className="bg-light text-danger border rounded"
            onClick={() => {
              clear("#formcontainer");
            }}
          >
            <FontAwesomeIcon icon={faX} className="me-1 p-1 align-middle" />
            انصراف
          </button>
        </div>

        <div className="w-100 border mt-3"></div>
      </div>
      <div
        onClick={() => {
          $("#formcontainer").hide(500);
        }}
        id="shadowDiv"
      ></div>
    </div>
  );
};

export default Form;
