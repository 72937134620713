import React, {useEffect, useState} from 'react'
import {faCircleXmark} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import "../../auth/auth.css"
import axios from 'axios'
import {useDispatch, useSelector} from 'react-redux'
import $ from "jquery";

const Adduser = (props) => {
    const setData = props.setDatas

    const[firstname,setFirstname]=useState(null)
    const[lastname,setLastname]=useState(null)
    const[familyrel,setFamilyrel]=useState(null)
    const[nationalcode,setNationalcode]=useState(null)
    const[personid,setPersonid]=useState(null)
    const[idserial,setIdserial]=useState(null)
    const[issueplace,setIssueplace]=useState(null)
    const[fathername,setFathername]=useState(null)
    const[mothername,setMothername]=useState(null)
    const[birthdaydate,setBirthdaydate]=useState(null)
    const[job,setJob]=useState(null)
    const[jobskill,setJobskill]=useState(null)
    const[phonenumber,setPhonenumber]=useState(null)
    const[insurancenumber,setInsurancenumber]=useState(null)
    const[deathdate,setDeathdate]=useState(null)
    const[educationlevel,setEducationlevel]=useState(null)
    const[militaryreport,setMilitaryreport]=useState(null)
    const[region,setRegion]=useState(null)
    const[religion,setReligion]=useState(null)
    const[religiontype,setReligiontype]=useState(null)
    const[insurancetype,setInsurancetype]=useState(null)
    const[married,setMarried]=useState(false)
    const[alive,setAlive]=useState(false)
    const[familysupervisor,setFamilysupervisor]=useState(false)
    const[veteran,setVeteran]=useState(false)
    const[freeman,setFreeman]=useState(false)
    const[selfless,setSelfless]=useState(false)
    const[mobilization,setMobilization]=useState(false)
    const[onrehabilitation,setOnrehabilitation]=useState(false)
  

    const data = {
      data_option:"اطلاعات اشخاص خانوار",
      firstname:firstname,
      lastname:lastname,
      familyrel:familyrel,
      nationalcode:nationalcode,
      personid:personid,
      idserial:idserial,
      issueplace:issueplace,
      fathername:fathername,
      mothername:mothername,
      birthdaydate:birthdaydate,
      job:job,
      jobskill:jobskill,
      phonenumber:phonenumber,
      insurancenumber:insurancenumber,
      deathdate:deathdate,
      educationlevel:educationlevel,
      militaryreport:militaryreport,
      region:region,
      religion:religion,
      religiontype:religiontype,
      insurancetype:insurancetype,
      married:married,
      alive:alive,
      familysupervisor:familysupervisor,
      veteran:veteran,
      freeman:freeman,
      selfless:selfless,
      mobilization:mobilization,
      onrehabilitation:onrehabilitation
    }

    return (
        <div id='shadow'
             className='d-flex w-80 ms-5 bg-light flex-column m-auto justify-content-center align-items-end p-3 rounded'>
            <FontAwesomeIcon id='close2' style={{color: 'blue'}} className='mx-2 align-middle fa-lg'
                             onClick={() => props.show("#adduser")} icon={faCircleXmark}/>
            <div style={{fontSize: '20px', fontWeight: '600'}} className="ms-auto">
                افزودن اکانت
            </div>
            <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                <div className='d-flex flex-column w-20 mx-3'>
                    <label className='mt-3'>نام </label>
                    <input className='form-control w-100 mt-2 text-end' onChange={(e)=>setFirstname(e.target.value)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-3'>نام خانوادگی </label>
                    <input className='form-control w-100 mt-2 text-end'onChange={(e)=>setLastname(e.target.value)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-3'> نسبت خانوادگی </label>
                    <input className='form-control w-100 mt-2 text-end' onChange={(e)=>setFamilyrel(e.target.value)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-3'> شماره ملی </label>
                    <input className='form-control w-100 mt-2 text-end' onChange={(e)=>setNationalcode(e.target.value)}/>
                </div>
                <div className='d-flex flex-column w-20 mx-3'>
                    <label className='mt-3'>شماره شناسنامه </label>
                    <input className='form-control w-100 mt-2 text-end' onChange={(e)=>setPersonid(e.target.value)}/>
                </div>

            </div>
            <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>

                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-3'>سریال شناسنامه </label>
                    <input className='form-control w-100 mt-2 text-end' onChange={(e)=>setIdserial(e.target.value)}/>
                </div>
                <div className='d-flex flex-column w-20 mx-3'>
                    <label className='mt-3'> محل صدور </label>
                    <input className='form-control w-100 mt-2 text-end' onChange={(e)=>setIssueplace(e.target.value)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-3'> نام پدر </label>
                    <input className='form-control w-100 mt-2 text-end' onChange={(e)=>setFathername(e.target.value)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-3'> نام مادر </label>
                    <input className='form-control w-100 mt-2 text-end' onChange={(e)=>setMothername(e.target.value)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-3'> تاریخ تولد </label>
                    <input type='date' className='form-control w-100 mt-2 text-end' onChange={(e)=>setBirthdaydate(e.target.value)}/>
                </div>

            </div>
            <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>

                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-3'>شغل </label>
                    <input className='form-control w-100 mt-2 text-end' onChange={(e)=>setJob(e.target.value)}/>
                </div>
                <div className='d-flex flex-column w-20 mx-3'>
                    <label className='mt-3'> مهارت شغلی </label>
                    <input className='form-control w-100 mt-2 text-end' onChange={(e)=>setJobskill(e.target.value)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-3'> شماره همراه </label>
                    <input className='form-control w-100 mt-2 text-end' onChange={(e)=>setPhonenumber(e.target.value)}/>
                </div>
                <div className='d-flex flex-column w-20 mx-3'>
                    <label className='mt-3'> شماره بیمه </label>
                    <input className='form-control w-100 mt-2 text-end' onChange={(e)=>setInsurancenumber(e.target.value)}/>
                </div>

                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-3'> تاریخ فوت </label>
                    <input type='date' className='form-control w-100 mt-2 text-end' onChange={(e)=>setDeathdate(e.target.value)}/>
                </div>

            </div>
            <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>

                <div className='d-flex flex-column w-10 mx-3 form-switch'>
                    <label className='mt-3'>متاهل/مجرد </label>
                    <input type='checkbox' className='mt-2 text-end form-control mx-auto form-check-input'
                    onChange={(e)=>setMarried(current=>!current)} />
                </div>
                <div className='d-flex mx-3 flex-column w-10 form-switch'>
                    <label className='mt-3'>زنده/فوت شده </label>
                    <input type='checkbox' className='mt-2 text-end form-control mx-auto form-check-input'
                    onChange={(e)=>setAlive(current=>!current)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-10 form-switch'>
                    <label className='mt-3'>سرپرست خانوار </label>
                    <input type='checkbox' className='mt-2 text-end form-control mx-auto form-check-input '
                    onChange={(e)=>setFamilysupervisor(current=>!current)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-10 form-switch'>
                    <label className='mt-3'> جانباز </label>
                    <input type='checkbox' className='mt-2 text-end form-control mx-auto form-check-input '
                    onChange={(e)=>setVeteran(current=>!current)}/>
                </div>
                <div className='d-flex flex-column w-10 mx-3 form-switch'>
                    <label className='mt-3'>آزاده </label>
                    <input type='checkbox' className='mt-2 text-end form-control mx-auto form-check-input '
                    onChange={(e)=>setFreeman(current=>!current)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-10 form-switch '>
                    <label className='mt-3'>ایثارگر </label>
                    <input type='checkbox' className='mt-2 text-end form-control mx-auto form-check-input '
                    onChange={(e)=>setSelfless(current=>!current)}/>
                </div>
                <div className='d-flex mx-3 flex-column w-10 form-switch'>
                    <label className='mt-3'> بسیجی </label>
                    <input type='checkbox' className='mt-2 text-end form-control mx-auto form-check-input '
                    onChange={(e)=>setMobilization(current=>!current)}/>
                </div>
                <div className='d-flex flex-column w-10 mx-3 form-switch'>
                    <label className='mt-3 text-nowrap'>تحت پوشش بهزیستی </label>
                    <input type='checkbox' className='mt-2 text-end form-control mx-auto form-check-input '
                    onChange={(e)=>setOnrehabilitation(current=>!current)}/>
                </div>
            </div>
            <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-3'>میزان تحصیلات </label>
                    <select className='form-select mt-2 text-center' onChange={(e)=>setEducationlevel(e.target.value)}>
                        <option value='بی سواد'>بی سواد</option>
                        <option value='سیکل'>سیکل</option>
                        <option value='دیپلم'>دیپلم</option>
                        <option value='لیسانس'>لیسانس</option>
                        <option value='دکتری'>دکتری</option>
                    </select>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-3'> وضعیت نظام وظیفه </label>
                    <select className='form-select mt-2 text-center' onChange={(e)=>setMilitaryreport(e.target.value)}>
                        <option value='اتمام'> اتمام</option>
                        <option value='معافیت دایم'> معافیت دایم</option>
                        <option value=' معافیت موقت'> معافیت موقت</option>
                        <option value='معافیت تحصیلی'>معافیت تحصیلی</option>
                    </select>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-3'> ملیت </label>
                    <select className='form-select mt-2 text-center' onChange={(e)=>setRegion(e.target.value)}>
                        <option value='ایران'> ایران</option>
                        <option value='افغان'> افغان</option>
                        <option value='سایر'> سایر</option>
                    </select>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-3'> دین </label>
                    <select className='form-select mt-2 text-center' onChange={(e)=>setReligion(e.target.value)}>
                        <option value='اسلام'> اسلام</option>
                        <option value='مسیحیت'> مسیحیت</option>
                        <option value='یهود'> یهود</option>
                        <option value='سایر'> سایر</option>
                    </select>
                </div>
                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-3'> مذهب </label>
                    <select className='form-select mt-2 text-center' onChange={(e)=>setReligiontype(e.target.value)}>
                        <option value='شیعه'> شیعه</option>
                        <option value='سایر'> سایر</option>
                    </select>
                </div>

                <div className='d-flex mx-3 flex-column w-20'>
                    <label className='mt-3'> نوع بیمه </label>
                    <select className='form-select mt-2 text-center' onChange={(e)=>setInsurancetype(e.target.value)}>
                        <option value='بدون بیمه'> بدون بیمه</option>
                        <option value='تامین اجتماعی'> تامین اجتماعی</option>
                        <option value='سایر'>سایر</option>
                    </select>
                </div>

            </div>


            <div className='d-flex flex-row justify-content-between w-100'>
                <button id='button' className='me-auto' onClick={(e)=>setData([data])} > افزودن اکانت</button>
            </div>
        </div>
    )
}

export default Adduser