import React, {useState} from 'react'
import './Tabs.css';

const Error = () => {

    const [errMsg, setErrMsg] = useState("error")


    return (
        <div className='w-100 h-100 d-flex m-2 flex-column'>
            <div className='mx-auto m-2 text-danger'> کد خطا : {errMsg}</div>
            <div className='mx-auto m-2 text-danger'>متن خطا : {errMsg}</div>
        </div>
    )
}

export default Error