import React from 'react'
import {faCheck, faX} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const Removeuser = (props) => {

    return (

        <div
            className='w-50 h-25 d-flex flex-column justify-content-center align-items-center rounded' id='bgadd'>
            <div className='d-flex text-dark'>
                آیا از پاک کردن کاربر <span className='mx-2 text-dark'>{props.firstname}</span> اطمینان دارید؟
            </div>
            <div className='d-flex flex-row justify-content-center align-items-center mt-4'>
                <button className='bg-light text-danger border rounded px-3 p-1 ms-5'
                        onClick={() => props.show("#removeRow")}><FontAwesomeIcon icon={faX} className="ms-1"/>خیر
                </button>
                <button className='bg-light text-success border rounded px-3 p-1'
                        onClick={() => props.handleDelete(props.id) & props.show("#removeRow")}><FontAwesomeIcon
                    icon={faCheck} className="ms-1"/>بله
                </button>
            </div>
        </div>
    )
}

export default Removeuser