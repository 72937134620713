import axios from 'axios';
import React, {useState} from 'react'
import { useRef } from 'react';
import './Form.css'

const MainForm = () => {

    const [checkcar, setCheckcar] = useState(true);
    const [checkani, setCheckani] = useState(true);

    const checkedcar = (e) => {
        setCheckcar(current => !current);
    };

    const checkedani = (e) => {
        setCheckani(current => !current);
    };

    const [firstname,setFirstname] = useState(null)
    const [lastname,setLastname] =useState(null)
    const [nationalcode,setNationalcode] =useState(null)
    const [personid,setPersonid] =useState(null)
    const [idserial,setIdserial] =useState(null)
    const [educationlevel,setEducationlevel] =useState(null)
    const [birthdaydate,setBirthdaydate] =useState(null)
    const [phonenumber,setPhonenumber] =useState(null)
    const [homenumber,setHomenumber] =useState(null)
    const [street,setStreet] =useState(null)
    const [alley,setAlley] =useState(null)
    const [plate,setPlate] =useState(null)
    const [zipcode,setZipcode] =useState(null)
    const [familynumber,setFamilynumber] =useState(null)
    const [address,setAddress] =useState(null)

    const [familysupervisor,setFamilysupervisor] =useState(false)
    const [married,setMarried] =useState(false)
    const [havejob,setHavejob] =useState(false)
    const [havehome,setHavehome] =useState(false)
    
    const [car,setCar] =useState("0")
    const [motorcycle,setMotorcycle] =useState("0")
    const [taxi,setTaxi] =useState("0")
    const [minibus,setMinibus] =useState("0")
    const [bus,setBus] =useState("0")
    const [truck,setTruck] =useState("0")
    const [heavycar,setHeavycar] =useState("0")

    const [cow,setCow] =useState("0")
    const [sheep,setSheep] =useState("0")
    const [goat,setGoat] =useState("0")
    const [camel,setCamel] =useState("0")
    const [birds,setBirds] =useState("0")
    const [chicken,setChiken] =useState("0")

    const [familymartyr,setFamilymartyr] =useState(false)
    const[immigrant,setImmigrant]=useState(false)
    const[onaid,setOnaid]=useState(false)
    const[havepc,setHavepc]=useState(false)
    const[havenet,setHavenet]=useState(false)
    const[onrehabilitation,setOnrehabilitation]=useState(false)
    const[nomads,setNomads]=useState(false)



const data={
    data_option:'اطلاعات شخصی',
    firstname :     firstname,
    lastname:lastname,
    nationalcode : nationalcode,
    personid : personid,
    idserial : idserial,
    educationlevel : educationlevel,
    birthdaydate :birthdaydate,
    phonenumber:phonenumber,
    homenumber :homenumber,
    street:street,
    alley:alley,
    plate:plate,
    zipcode:zipcode,
    familynumber:familynumber,
    address:address,
    familysupervisor:familysupervisor,
    married:married,
    havejob:havejob,
    havehome:havehome,
    car:car,
    motorcycle:motorcycle,
    taxi:taxi,
    minibus:minibus,
    bus:bus,
    truck:truck,
    heavycar:heavycar,
    cow:cow,
    sheep:sheep,
    goat:goat,
    camel:camel,
    birds:birds,
    chicken:chicken,
    familymartyr:familymartyr,
    immigrant:immigrant,
    onaid:onaid,
    havepc:havepc,
    havenet:havenet,
    onrehabilitation:onrehabilitation,
    nomads:nomads,

}

const sendform =()=>{
    axios.post('http://192.168.1.136/api/v01/api-select.php', data)
        .then(res => {
          
        })
}


    return (
        <div className='w-100 p-3 mt-1 mx-auto rounded d-flex flex-row justify-content-start align-items-start'
              id='formshadow' style={{backgroundColor: '#fff', color: '#6E6E7E'}} dir='rtl'>
            <div className='d-flex flex-column' style={{borderLeft: '2px solid lightblue', width: '40%'}}>
                <div className='w-100'>
                    <div className='ms-auto text-dark' style={{color: 'black', fontWeight: '600'}}> فرم جمع آوری اطلاعات
                        شخص
                    </div>
                    <div className='d-flex flex-row my-3 text-dark'>
                        <div className='w-25'>نام :</div>
                        <input className='mx-auto  form-control w-50' onChange={(e)=>setFirstname(e.target.value)} placeholder='نام'/>
                    </div>
                    <div className='d-flex flex-row my-3 text-dark'>
                        <div className='w-25'>نام خانوادگی :</div>
                        <input className='mx-auto form-control w-50' onChange={(e)=>setLastname(e.target.value)} placeholder=' نام خانوادگی'/>
                    </div>
                    <div className='d-flex flex-row my-3 text-dark'>
                        <div className='w-25'> کد ملی :</div>
                        <input className='mx-auto form-control w-50' onChange={(e)=>setNationalcode(e.target.value)} placeholder=' کد ملی '/>
                    </div>
                    <div className='d-flex flex-row my-3 text-dark'>
                        <div className='w-25'> شماره شناسنامه :</div>
                        <input className='mx-auto form-control w-50' onChange={(e)=>setPersonid(e.target.value)} placeholder='  شماره شناسنامه '/>
                    </div>
                    <div className='d-flex flex-row my-3 text-dark'>
                        <div className='w-25'> سریال شناسنامه :</div>
                        <input className='mx-auto form-control w-50' onChange={(e)=>setIdserial(e.target.value)} placeholder='سریال شناسنامه '/>
                    </div>
                    <div className='d-flex flex-row my-3 text-dark'>
                        <div className='w-25'> میزان تحصیلات :</div>
                        <input className='mx-auto form-control w-50' onChange={(e)=>setEducationlevel(e.target.value)} placeholder='میزان تحصیلات '/>
                    </div>
                    <div className='d-flex flex-row my-3 text-dark'>
                        <div className='w-25'>تاریخ تولد :</div>
                        <input type='date' className='rounded border' onChange={(e)=>setBirthdaydate(e.target.value)}/>
                    </div>
                    <div className='d-flex flex-row my-3 text-dark form-check form-switch align-items-center'>
                        <div className='w-25'> سرپرست خانوار:</div>
                        <input id="flexSwitchCheckChecked" onChange={(e)=>setFamilysupervisor(current=>!current)} className='form-check-input mx-auto' type="checkbox"
                               role="switch"/>
                    </div>

                    <div className='me-5 text-dark my-4' style={{color: 'black', fontWeight: '600'}}> اطلاعات خانواده
                    </div>


                    <div className='d-flex flex-row my-3 text-dark form-check form-switch align-items-center'>
                        <div className='w-25'> متاهل :</div>
                        <input id="flexSwitchCheckChecked" className='form-check-input' type="checkbox" role="switch" onChange={(e)=>setMarried(current=>!current)}/>
                        <div className='me-5 w-25'> شاغل :</div>
                        <input id="flexSwitchCheckChecked" className='form-check-input' type="checkbox" role="switch" onChange={(e)=>setHavejob(current=>!current)}/>
                    </div>

                    <div className='me-5 text-dark my-4' style={{color: 'black', fontWeight: '600'}}> اطلاعات تماس</div>
                    <div className='d-flex flex-row my-3 text-dark'>
                        <div className='w-25'> شماره همراه :</div>
                        <input className='mx-auto form-control w-50' placeholder='  شماره همراه ' onChange={(e)=>setPhonenumber(e.target.value)}/>
                    </div>
                    <div className='d-flex flex-row my-3 text-dark'>
                        <div className='w-25'> شماره منزل :</div>
                        <input className='mx-auto form-control w-50' placeholder='  شماره منزل ' onChange={(e)=>setHomenumber(e.target.value)}/>
                    </div>

                </div>
            </div>
            <div className='d-flex p-2 flex-column justify-content-end' style={{width: '60%'}}>
                <div className=' text-dark' style={{color: 'black', fontWeight: '600'}}> اطلاعات خانوار</div>

                <div className='d-flex flex-row my-3 text-dark w-100 justify-content-between '>
                    <div className='d-flex flex-column w-20'>
                        <label htmlFor='phone form-label'> خیابان :</label>
                        <input className='mx-auto form-control my-2' name='phone' placeholder='خیابان' onChange={(e)=>setStreet(e.target.value)}/>
                    </div>
                    <div className='d-flex flex-column w-20'>
                        <label htmlFor='phone form-label'> کوچه :</label>
                        <input className='mx-auto form-control my-2' name='phone' placeholder=' کوچه' onChange={(e)=>setAlley(e.target.value)}/>
                    </div>
                    <div className='d-flex flex-column w-20'>
                        <label htmlFor='phone form-label'> پلاک :</label>
                        <input className='mx-auto form-control my-2' name='phone' placeholder=' پلاک' onChange={(e)=>setPlate(e.target.value)}/>
                    </div>
                    <div className='d-flex flex-column w-20'>
                        <label htmlFor='phone form-label'> کد پستی :</label>
                        <input className='mx-auto form-control my-2' name='phone' placeholder=' کد پستی' onChange={(e)=>setZipcode(e.target.value)}/>
                    </div>
                    <div className='d-flex flex-column w-20'>
                        <label htmlFor='phone form-label'> تلفن :</label>
                        <input className='mx-auto form-control my-2' name='phone' placeholder=' تلفن' onChange={(e)=>setFamilynumber(e.target.value)} />
                    </div>
                </div>
                <div className='d-flex flex-row my-3 text-dark w-100 justify-content-between '>
                    <div className='d-flex flex-column w-50'>
                        <label htmlFor='phone form-label'> آدرس کامل :</label>
                        <textarea className='mx-auto form-control my-2' name='phone' placeholder=' آدرس کامل ' onChange={(e)=>setAddress(e.target.value)}/>
                    </div>
                    <div className='d-flex flex-column w-50 h-100 align-items-center jusitfy-content-center'>
                        <label htmlFor='phone form-label'> وضعیت مسکن :</label>
                        <div className='d-flex flex-row mt-4'>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault"
                                       id="flexRadioDefault1" onChange={()=>setHavehome(true)}/>
                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    صاحب مسکن
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault"
                                       id="flexRadioDefault1" onChange={()=>setHavehome(false)}/>
                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    مستاجر
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=' form-switch d-flex flex-row justify-content-start'>
                    <div className=' text-dark' style={{color: 'black', fontWeight: '600'}}> وسایل نقلیه</div>
                    <input className='ms-auto me-4 form-control form-check-input' onChange={(e) => checkedcar(e)}
                           id="flexSwitchCheckChecked"
                           type="checkbox" role="switch"/>
                </div>
                {checkcar ? <div className='d-flex flex-row my-3 text-dark w-100 p-1 text-nowrap rounded'
                                 style={{border: '1px solid lightblue'}}>
                    <div className='d-flex flex-column w-125'>
                        <label htmlFor='form-label'> خودرو </label>
                        <input className='mx-auto form-control my-2' type='number' placeholder='0' onChange={(e)=>setCar(e.target.value)}/>
                    </div>
                    <div className='d-flex flex-column w-125'>
                        <label htmlFor='form-label'> موتور </label>
                        <input className='mx-auto form-control my-2' type='number' placeholder='0' onChange={(e)=>setMotorcycle(e.target.value)}/>
                    </div>
                    <div className='d-flex flex-column w-125'>
                        <label htmlFor='form-label'> تاکسی </label>
                        <input className='mx-auto form-control my-2' type='number' placeholder='0' onChange={(e)=>setTaxi(e.target.value)}/>
                    </div>
                    <div className='d-flex flex-column w-125'>
                        <label htmlFor='form-label'> مینی بوس </label>
                        <input className='mx-auto form-control my-2' type='number' placeholder='0' onChange={(e)=>setMinibus(e.target.value)}/>
                    </div>
                    <div className='d-flex flex-column w-125'>
                        <label htmlFor='form-label'> اتوبوس </label>
                        <input className='mx-auto form-control my-2' type='number' placeholder='0' onChange={(e)=>setBus(e.target.value)}/>
                    </div>
                    <div className='d-flex flex-column w-125'>
                        <label htmlFor='form-label'> کامیونت </label>
                        <input className='mx-auto form-control my-2' type='number' placeholder='0' onChange={(e)=>setTruck(e.target.value)}/>
                    </div>
                    <div className='d-flex flex-column w-20'>
                        <label htmlFor='form-label'> خودرو سنگین </label>
                        <input className='mx-auto form-control mt-2' type='number' placeholder='0' onChange={(e)=>setHeavycar(e.target.value)}/>
                    </div>

                </div> : null}

                <div>

                </div>

                <div className=' form-switch d-flex flex-row justify-content-start'>
                    <div className=' text-dark' style={{color: 'black', fontWeight: '600'}}>اطلاعات دام و طیور</div>
                    <input className='ms-auto me-4 form-control form-check-input' id="flexSwitchCheckChecked"
                           type="checkbox" onChange={(e) => {
                        checkedani(e)
                    }} role="switch"/>
                </div>

                {checkani ? <div className='d-flex flex-row my-3 text-dark w-100 p-1 text-nowrap rounded'
                                 style={{border: '1px solid lightblue'}}>
                    <div className='d-flex flex-column w-166'>
                        <label htmlFor='form-label'> گاو </label>
                        <input className='mx-auto form-control my-2' type='number' placeholder='0' onChange={(e)=>setCow(e.target.value)}/>
                    </div>
                    <div className='d-flex flex-column w-166'>
                        <label htmlFor='form-label'> گوسفند </label>
                        <input className='mx-auto form-control my-2' type='number' placeholder='0' onChange={(e)=>setSheep(e.target.value)}/>
                    </div>
                    <div className='d-flex flex-column w-166'>
                        <label htmlFor='form-label'> بز </label>
                        <input className='mx-auto form-control my-2' type='number' placeholder='0' onChange={(e)=>setGoat(e.target.value)}/>
                    </div>
                    <div className='d-flex flex-column w-166'>
                        <label htmlFor='form-label'> شتر </label>
                        <input className='mx-auto form-control my-2' type='number' placeholder='0' onChange={(e)=>setCamel(e.target.value)}/>
                    </div>
                    <div className='d-flex flex-column w-166'>
                        <label htmlFor='form-label'> طیور </label>
                        <input className='mx-auto form-control my-2' type='number' placeholder='0' onChange={(e)=>setBirds(e.target.value)}/>
                    </div>
                    <div className='d-flex flex-column w-166'>
                        <label htmlFor='form-label'> مرغ </label>
                        <input className='mx-auto form-control my-2' type='number' placeholder='0' onChange={(e)=>setChiken(e.target.value)}/>
                    </div>


                </div> : null}


                <div className=' text-dark' style={{color: 'black', fontWeight: '600'}}>خانواده شهدا</div>

                <div className='d-flex flex-row my-3 text-dark w-100 p-1 text-nowrap rounded'
                     style={{border: '1px solid lightblue', fontSize: '12px'}}>
                    <div className='d-flex flex-column w-166 form-switch justify-content-end'>
                        <label htmlFor='form-label'> خانواده شهید </label>
                        <input className='mx-auto form-control my-2 form-check-input' id="flexSwitchCheckChecked"
                               type="checkbox" role="switch" onChange={(e)=>setFamilymartyr(current=>!current)}/>
                    </div>
                    <div className='d-flex flex-column w-166 form-switch justify-content-end'>
                        <label htmlFor='form-label'>  مهاجر </label>
                        <input className='mx-auto form-control my-2 form-check-input' id="flexSwitchCheckChecked"
                               type="checkbox" role="switch" onChange={(e)=>setImmigrant(current=>!current)}/>
                    </div>
                    <div className='d-flex flex-column w-166 form-switch'>
                        <label htmlFor='form-label'> تحت پوشش کمیته </label>
                        <input className='mx-auto form-control my-2 form-check-input' id="flexSwitchCheckChecked"
                               type="checkbox" role="switch" onChange={(e)=>setOnaid(current=>!current)}/>
                    </div>
                    <div className='d-flex flex-column w-166 form-switch'>
                        <label htmlFor='form-label'> دسترسی به کامپیوتر </label>
                        <input className='mx-auto form-control my-2 form-check-input' id="flexSwitchCheckChecked"
                               type="checkbox" role="switch" onChange={(e)=>setHavepc(current=>!current)}/>
                    </div>
                    <div className='d-flex flex-column w-166 form-switch'>
                        <label htmlFor='form-label'> دسترسی به اینترنت </label>
                        <input className='mx-auto form-control my-2 form-check-input' id="flexSwitchCheckChecked"
                               type="checkbox" role="switch" onChange={(e)=>setHavenet(current=>!current)}/>
                    </div>
                    <div className='d-flex flex-column w-166 form-switch'>
                        <label htmlFor='form-label'> تحت پوشش بهزیستی </label>
                        <input className='mx-auto form-control my-2 form-check-input' id="flexSwitchCheckChecked"
                               type="checkbox" role="switch" onChange={(e)=>setOnrehabilitation(current=>!current)}/>
                    </div>
                    <div className='d-flex flex-column w-166 form-switch'>
                        <label htmlFor='form-label'> خانوار عشایری </label>
                        <input className='mx-auto form-control my-2 form-check-input' id="flexSwitchCheckChecked"
                               type="checkbox" role="switch" onChange={(e)=>setNomads(current=>!current)}/>
                    </div>


                </div>
                <button id='button' type='submit' onClick={()=>sendform()}  className='me-auto w-25 text-light text-nowrap'  > افزودن شخص</button>

            </div>


        </div>
    )
}

export default MainForm