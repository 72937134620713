import axios from 'axios'
import React ,{useState}from 'react'
import { useSelector , useDispatch } from 'react-redux'
import{
  addemail,
  addpassword,
 addpasswordcheck
} from "../redux/dataActions"

const ChangePass = () => {

  const password = useSelector((state)=>state.password)
  const id = useSelector((state)=>state.id)
  const [oldpass,setOldpass] = useState(null)
  const [newpass,setNewpass] = useState(null)
  const [newpasscheck,setNewpasscheck] = useState(null)
  const [err,setErr] = useState(null)


  const url = 'http://192.168.1.136/api/v01/api-signup.php'
  // change pass url

  const data={
    data_option : 'password_update',
    oldpassword: oldpass,
    password: newpass,
    id:id,
  }

  const changePass = ()=>{

    if(oldpass === password && newpass === newpasscheck){
        axios.post(url,data).then(res =>{
            console.log(res)
          })
    }else{
        setErr('پسوورد همخوانی ندارد')
    }
  }

  return (
    <div className='w-50 bg-light rounded d-flex flex-column justify-content-start align-items-center p-3 ' id='shadow' dir='rtl'>
        <div className='w-100 d-flex flex-row align-items-center'>
                <label className='w-25'>رمز عبور فعلی</label>
                <input onChange={(e)=>setOldpass(e.target.value)} type='password' className='w-50 form-control'/>
                <div className='mx-auto text-danger fa-sm'>{err}</div>
        </div>
        <div className='w-100 d-flex flex-row align-items-center my-3'>
                <label className='w-25'>رمز عبور جدید</label>
                <input onChange={(e)=>setNewpass(e.target.value)} type='password' className='w-50 form-control'/>
        </div>
        <div className='w-100 d-flex flex-row align-items-center'>
                <label className='w-25'>تکرار رمز عبور جدید </label>
                <input onChange={(e)=>setNewpasscheck(e.target.value)} type='password' className='w-50 form-control'/>
        </div>
     
      <button className='p-2 text-light rounded mt-3 me-auto ms-4' style={{backgroundColor:'#4276DD'}} onClick={changePass}>
        تغییر رمز
      </button>
     
      
    </div>
  )
}

export default ChangePass