import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "./managePer.css";

const CreatePer = () => {
  const initialState = [];
  const [parents, setParents] = useState(null);
  const [childs, setChilds] = useState([]);
  const [gruops, setGroups] = useState([]);
  const [per, setPer] = useState([]);
  const [inputVal, setInputVal] = useState(null);
  const ref = useRef(null);
  const url = "http://192.168.1.136/api/v01/api-select.php";

  useEffect(() => {
    axios
      .post(url, {
        data_option: 3,
      })
      .then((res) => {
        console.log(childs.length);
        if (childs.length < 1) {
          for (let i = 0; i < res.data.data.permission_name.length; i++) {
            setChilds((current) => [...current, res.data.data.permission_name[i]]);
          }
          console.log("ok");
        } else {
          setChilds(null);
        }
      });
  }, []);

  useEffect(() => {
    axios
      .post(url, {
        data_option: 2,
      })
      .then((res) => {
        for (let i = 0; i < res.data.data.permission_groupe_name.length; i++) {
          setGroups((current) => [...current, res.data.data.permission_groupe_name[i]]);
        }
      });
  }, []);

  console.log(gruops);
  const postData = () => {
    axios.post(url, {
      groupname: inputVal,
      per: per,
    });
  };

  function check(e) {
    if (per.includes(e)) {
      let x = per.indexOf(e);
      per.splice(x, 1);
    } else {
      setPer((current) => [...current, e]);
    }
  }

  console.log(per);

  return (
    <div className="d-flex flex-column justify-content-center align-items-end w-100 bg-light text-dark ms-auto p-3 rounded" style={{ marginTop: 80 }} id="shadow">
      <div className="d-flex flex-row w-100 align-items-center" dir="rtl">
        <div className="ms-4">لطفا گروه را انتخاب کنید :</div>
        <select className="w-18 p-2 mx-3 form-control" onChange={(e) => setInputVal(e.target.value)}>
          <option defaultChecked>لطفا انتخاب کنید</option>

          {gruops.map((child) => (
            <option>
              {child.id} - {child.groupe_name}
            </option>
          ))}
        </select>
      </div>
      <div className="w-100 d-flex flex-row justify-content-around align-items-start p-2 bg-light rounded text-dark mt-4" ref={ref} dir="rtl" id="parentaldiv">
        {childs.map((el) => (
          <div className="d-flex w-25 flex-wrap my-2 flex-row justify-content-center align-items-center form-switch switcher" style={{ fontSize: "15px", fontWeight: "700", color: "#202124" }}>
            <div className="w-75 no-wrap">{el.permission_name}</div>
            <input className=" form-control form-check-input" type="checkbox" onChange={(e) => check(el.id)} role="switch" />
          </div>
        ))}
      </div>
      <button className="p-2 text-light mt-3 rounded me-auto" onClick={postData} style={{ backgroundColor: "rgb(33, 101, 190)" }} id="shadow">
        ذخیره دسترسی ها
      </button>
    </div>
  );
};

export default CreatePer;
