export const ADD_FIRSTNAME = "ADD_FIRSTNAME";
export const ADD_LASTNAME = "ADD_LASTNAME";
export const ADD_EMAIL = "ADD_EMAIL";
export const ADD_PHONE = "ADD_PHONE";
export const ADD_PASSWORD = "ADD_PASSWORD";
export const ADD_PASSWORDCHECK = "ADD_PASSWORDCKECK";
export const ADD_USERNAME = "ADD_USERNAME";
export const ADD_CODEMELI = "ADD_CODEMELI";
export const ADD_USER = "ADD_USER";
export const ADD_ID = "ADD_ID";
export const SIDEBAR_BG = "SIDEBAR_BG";
export const SIDEBAR_FONT = "SIDEBAR_FONT";
export const USER_IMG = 'USER_IMG';
