import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CDBSidebar, CDBSidebarMenu } from "cdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faBars, faHome, faMap, faPager, faUser } from "@fortawesome/free-solid-svg-icons";
import "../App.css";
import $ from "jquery";
import { useSelector } from "react-redux";

function Sidebar() {
  const sidebarbg = useSelector((state) => state.sidebar_bg);
  const sidebarfont = useSelector((state) => state.sidebar_font);
  const [icon, setIcon] = useState(faArrowDown);

  function show(e) {
    $(e).toggle(500);
  }

  return (
    <div>
      <div
        id="hamburger"
        onClick={() => {
          $("#sidebarbg").show(500);
          $("#closeRes").show(500);
          $("#hamburger").hide(500);
        }}
      >
        <FontAwesomeIcon icon={faBars} id="clicked" className="mx-2 mt-2" />
      </div>

      <div
        id="sidebar"
        className="justify-content-center align-items-center"
        style={{
          display: "flex",
          height: "100vh",
          overflow: "scroll initial",
          top: "0",
          position: "absolute",
          right: "0",
        }}
        dir="rtl"
      >
        <CDBSidebar id="sidebarbg" style={{ backgroundColor: `${sidebarbg}` }} textColor={sidebarfont}>
          <div id="sidebarres" className="sidebar-content text-end">
            <CDBSidebarMenu>
              <div className=" w-100 d-flex flex-row justify-content-start align-items-center">
                <Link to="/" style={{ color: `${sidebarfont}` }} className="mt-4 rounded">
                  <FontAwesomeIcon icon={faHome} className="mx-2  mt-2" /> داشبورد(per-id)
                </Link>
                <div id="closeRes" className="d-flex ms-4 me-auto" style={{ display: "none" }}>
                  <FontAwesomeIcon
                    style={{ color: "#4276dd", fontSize: "30px" }}
                    onClick={() => {
                      $("#sidebarbg").hide(500);
                      $("#closeRes").hide(500);
                      $("#hamburger").show(500);
                    }}
                    icon={faBars}
                  />
                </div>
              </div>

              {/* <Link to="/map" onClick={() => show("#dashboard")}>
                                <div className='mt-3 rounded p-2 align-middle' style={{color: `${sidebarfont}`}}>
                                    <FontAwesomeIcon icon={faUser} className=" mx-2 "/> تجارت
                                    الکترونیک<FontAwesomeIcon icon={icon} className="me-5"/></div>
                            </Link> */}

              <div id="dashboard" className="me-5 mt-1 p-2 rounded" style={{ display: "none" }}>
                <div>داشبورد</div>
                <div>محصولات</div>
                <div>جزییات محصول</div>
                <div>سبد خرید</div>
                <div>پرداخت</div>
                <div>سفارشات</div>
                <div>حساب</div>
                <div>نامه</div>
              </div>

              {/* <Link to="/users" className="mt-3">
                <div className="p-2  mt-3" style={{ color: `${sidebarfont}` }}>
                  <FontAwesomeIcon icon={faUser} className=" mx-2 " /> کاربران
                </div>
              </Link> */}
              {/* <Link to="/users2" className="mt-3">
                <div className="p-2  mt-3" style={{ color: `${sidebarfont}` }}>
                  <FontAwesomeIcon icon={faUser} className=" mx-2 " /> کابران ۲
                </div>
              </Link> */}

              <Link id="open" to="/map" className="mt-3" onClick={() => show("#mapoptions")}>
                <div className="p-2  mt-3" style={{ color: `${sidebarfont}` }}>
                  <FontAwesomeIcon icon={faMap} className=" mx-2" />
                  نقشه
                </div>
              </Link>
              <div id="mapoptions" className="me-5 mt-1 p-2 rounded" style={{ display: "none" }}>
                <Link to="/map">ثبت مکان بر روی نقشه</Link> <br />
                <Link to="/list" className="mt-3">
                  لیست مکان های ثبت شده
                </Link>
                <br />
                <Link className="mt-3" to="/markedmap">
                  مکان ها بر روی نقشه
                </Link>
              </div>
              {/* <Link className="mt-3" onClick={() => show("#letteroptions")}>
                <div className="p-2 mt-3" style={{ color: `${sidebarfont}` }}>
                  <FontAwesomeIcon icon={faMap} className=" mx-2" />
                  نامه
                </div>
              </Link> */}
              <div id="letteroptions" className="me-5 mt-1 p-2 rounded" style={{ display: "none" }}>
                <div>صندوق پستی</div>
                <div>مشاهده نامه</div>
              </div>
              {/* <Link className="mt-3" onClick={() => show("#appoptions")}>
                <div className="p-2  mt-3" style={{ color: `${sidebarfont}` }}>
                  <FontAwesomeIcon icon={faMap} className=" mx-2 " />
                  برنامه ها
                </div>
              </Link> */}
              <div id="appoptions" className="me-5 mt-1 p-2 rounded" style={{ display: "none" }}>
                <div>گپ زدن</div>
                <div>کارتها</div>
                <div>تقویم</div>
                <div>مخاطب</div>
              </div>
              {/* <Link className="mt-3" onClick={() => show("#pageoptions")}>
                <div className="p-2 mt-3" style={{ color: `${sidebarfont}` }}>
                  <FontAwesomeIcon icon={faPager} className=" mx-2" />
                  صفحات
                </div>
              </Link> */}
              <div id="pageoptions" className="me-5 mt-1 p-2 rounded" style={{ display: "none" }}>
                <div>مشخصات</div>
                <div>صورتحساب</div>
                <div>قیمت گذاری</div>
                <div>گالری</div>
                <div>سوالات</div>
                <div>پیام موفقیت آمیز</div>
                <div>پیام خطر</div>
                <div>پیام هشدار</div>
                <div>صفحه خالی</div>
              </div>
              <Link className="mt-3" onClick={() => show("#usersoptions")}>
                <div className="p-2 mt-3" style={{ color: `${sidebarfont}` }}>
                  <FontAwesomeIcon icon={faPager} className=" mx-2" />
                  تنظیمات سیستم
                </div>
              </Link>
              <div id="usersoptions" className="me-5 mt-1 p-1 rounded" style={{ display: "none" }}>
                <Link to="/manageusers">مدیریت کاربران</Link>
                <br />
                <Link to="/groupuser" className="my-1">
                  تعریف گروه کاربران
                </Link>
                <br />
                <Link className="my-1" to="/Grouplevelper">
                  {" "}
                  تعریف سطح دسترسی برای گروه
                </Link>
                <Link to="/createper" className="my-1">
                  {" "}
                  تعریف خود سطح دسترسی
                </Link>
                <div> جدول آزمایشی</div>
              </div>
              <Link className="mt-3" onClick={() => show("#worldoptions")}>
                <div className="p-2  mt-3" style={{ color: `${sidebarfont}` }}>
                  <FontAwesomeIcon icon={faPager} className=" mx-2" /> اطلاعات پایه
                </div>
              </Link>
              <div id="worldoptions" className="me-5 mt-1 p-2 rounded" style={{ display: "none" }}>
                <Link to="/mapstates">اطلاعات جغرافیایی</Link>
                <div>کشور</div>
                <div>استان</div>
                <div>شهر</div>
                <div>روستا</div>
                <div>نام اتحادیه</div>
                <div>نام صنف</div>
                <div>واحد های پولی</div>
                <div>واحد اندازه گیری</div>
                <div>کالا</div>
                <div>خدمات</div>
                <div>تعریف شرکت</div>
                <div>تعریف سال مالی</div>
                <div>تعریف هزینه</div>
              </div>
              {/* <Link id="" to="/map" className="" onClick={() => show("#testoptions")}>
                <div className="p-2 mt-3" style={{ color: `${sidebarfont}` }}>
                  <FontAwesomeIcon icon={faUser} className=" mx-2 " />
                  منو ها
                  <FontAwesomeIcon icon={faArrowDown} className=" me-5 " />
                </div>
              </Link> */}
              <div id="testoptions" className="me-5 mt-3  p-2 rounded" style={{ display: "none" }}>
                <div>test</div>
                <div>test</div>
                <div>test</div>
              </div>
            </CDBSidebarMenu>
          </div>
        </CDBSidebar>
      </div>
    </div>
    // <Link to="/map" className="text-white text-decoration-none mt-3">Map</Link>
    // <Link to="/users" className="text-white text-decoration-none mt-3">Users</Link>
    // <Link to="/users2" className="text-white text-decoration-none mt-3">Users</Link>
  );
}

export default Sidebar;
