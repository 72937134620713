import {
    ADD_CODEMELI,
    ADD_EMAIL,
    ADD_FIRSTNAME,
    ADD_ID,
    ADD_LASTNAME,
    ADD_PASSWORD,
    ADD_PASSWORDCHECK,
    ADD_PHONE,
    ADD_USER,
    ADD_USERNAME,
    SIDEBAR_BG,
    SIDEBAR_FONT,
    USER_IMG
} from "./dataTypes";
import logo from '../images/logo.jpg';


const data = {
    firstname: '',
    lastname: '',
    phone: '',
    password: '',
    passwordcheck: '',
    email: "",
    username: '',
    codemeli: '',
    user: '',
    id: '',
    sidebar_bg: 'rgba(255, 255, 255, 0.9)',
    sidebar_font: '#4B5152',
    user_img: logo,
}

const dataReducer = (state = data, action) => {
    switch (action.type) {
        case ADD_USER:
            return {...state, user: action.payload}
        case ADD_USERNAME:
            return {...state, username: action.payload};
        case ADD_FIRSTNAME:
            return {...state, firstname: action.payload};
        case ADD_LASTNAME:
            return {...state, lastname: action.payload};
        case ADD_ID :
            return {...state, id: action.payload};
        case ADD_PHONE:
            return {...state, phone: action.payload};
        case ADD_CODEMELI:
            return {...state, codemeli: action.payload};
        case ADD_EMAIL:
            return {...state, email: action.payload};
        case ADD_PASSWORD:
            return {...state, password: action.payload};
        case ADD_PASSWORDCHECK:
            return {...state, passwordcheck: action.payload};
        case SIDEBAR_BG:
            return {...state, sidebar_bg: action.payload};
        case SIDEBAR_FONT:
            return {...state, sidebar_font: action.payload};
        case USER_IMG :
            return {...state, user_img: action.payload};


        default:
            return state;

    }
}

export default dataReducer