import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "./managePer.css";
import $ from "jquery";

const Permission = () => {
  const initialState = [];
  const [parents, setParents] = useState(null);
  const [childs, setChilds] = useState([]);
  const [per, setPer] = useState(null);
  const [inputVal, setInputVal] = useState(null);
  const ref = useRef(null);
  const url = "http://192.168.1.136/api/v01/api-select.php";

  useEffect(() => {
    axios
      .post(url, {
        data_option: 3,
      })
      .then((res) => {
        console.log(childs.length);
        if (childs.length < 1) {
          for (let i = 0; i < res.data.data.permission_name.length; i++) {
            setChilds((current) => [...current, res.data.data.permission_name[i]]);
          }
          console.log("ok");
        } else {
          setChilds(null);
        }
      });
  }, []);

  console.log(childs.length);

  const postPer = () => {
    per ? (
      axios.post("https://192.168.1.136/api/v01/api-new-per.php", {
        newpername: per,
        parentid: inputVal,
      })
    ) : (
      <></>
    );
  };

  useEffect(() => {
    var tree = ref.current;
    for (var i = 0; i < childs.length; ++i) {
      var msg_draft = childs[i].permission_name;
      if (childs[i].parental == null || childs[i].parental == "0") {
        createTreeElement("li", childs[i].id, msg_draft, tree);
      } else {
        var treeChildNode = document.getElementById("t" + childs[i].parental).getElementsByTagName("ul");
        if (treeChildNode.length) {
          createTreeElement("li", childs[i].id, msg_draft, treeChildNode[0]);
        } else {
          createTreeElement("button", "buttons", "+", document.getElementById("t" + childs[i].parental));
          createTreeElement("ul", childs[i].parental, "", document.getElementById("t" + childs[i].parental));
          createTreeElement("li", childs[i].id, msg_draft, document.getElementById("t" + childs[i].parental).getElementsByTagName("ul")[0]);
        }
      }
    }

    function createTreeElement(name, id, text, parent) {
      var node = document.createElement(name);
      node.id = "t" + id;
      node.innerHTML = text;
      parent.appendChild(node);
    }

    $("li").children().hide();
    $("button").on("click", function () {
      $(this).parent().parent().parent().children("button").hide();
      $(this).parent().children("ul").toggle(500);
    });
  }, [childs]);

  return (
    <div className="d-flex flex-column justify-content-center align-items-end w-100 bg-light text-dark ms-auto p-3 rounded" style={{ marginTop: 80 }} id="shadow">
      <div className="d-flex flex-row w-100 align-items-center" dir="rtl">
        <div className="ms-4">نام سطح دسترسی :</div>
        <input className="form-control w-25 h-50" onChange={(e) => setPer(e.target.value)} />
        <select className="w-18 p-2 mx-3 form-control" onChange={(e) => setInputVal(e.target.value)}>
          <option defaultChecked>لطفا انتخاب کنید</option>
          <option id="border1" value="0">
            0 - سرمجموعه
          </option>
          {childs.map((child) => (
            <option>
              {child.id} - {child.permission_name}
            </option>
          ))}
        </select>
        <button className="btn text-light me-auto" style={{ backgroundColor: "#0077B6" }} onClick={postPer}>
          افزودن
        </button>
      </div>
      <div className="w-100 d-flex flex-row justify-content-around align-items-start p-2 bg-light rounded text-dark mt-4" ref={ref} dir="rtl" id="parentaldiv"></div>
    </div>
  );
};

export default Permission;
