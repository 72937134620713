import React, {useEffect, useState} from "react";
import {MapContainer, Marker, Popup, TileLayer, useMap} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLocation} from '@fortawesome/free-solid-svg-icons'
import icon from "./constants";
import LocationMarker from "./LocationMarker";
import InfClickedpos from "./InfClickedpos";
import Form from "./Form";


export default function Map() {

    const [show, setShow] = useState(true);
    const [numbers, setNumbers] = useState(false);
    const [position, setPosition] = useState(null);


    function ClickPos() {

        const map = useMap();
        useEffect(() => {
            map.addEventListener("click", function (e) {
                setPosition(e.latlng)
            })
        }, [1]);


    }

    return (
        <div id='map'>
            <div id='marker'><FontAwesomeIcon onClick={() => setNumbers(prevNumbers => !prevNumbers)}
                                              style={{color: "blue"}} icon={faLocation}/></div>
            <InfClickedpos pos={position}/>
            <Form pos={position}/>
            <MapContainer
                id="ok"
                center={[36.8409, 54.4274]}
                zoom={18}
                scrollWheelZoom
                style={{height: "100vh", width: "100vw"}}
            >
                <TileLayer

                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarker numbers={numbers} setNumbers={setNumbers}/>
                <ClickPos/>
                {position === null ? null : <div>

                    <Marker position={position} icon={icon}>
                        <Popup>
                            You are here. <br/>

                        </Popup>
                    </Marker>
                </div>}
            </MapContainer>

        </div>
    );
}
