import React, { useEffect, useState } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Map from "./components/Map/Map";
import Table from "./components/Table/Table";
import Topmenu from "./components/Topmenu";
import Tabs from "./components/Table/Tabs";
import Markedmap from "./components/Map/Markedmap";
import Maptabs from "./components/mapTabs/Maptabs";
import Manageusers from "./components/System-Setting/Manageusers";
import { addcodemeli, addemail, addfirstname, addid, addlastname, addpassword, addphone, adduser, sidebg, sidefont } from "./components/redux/dataActions";
import { useDispatch } from "react-redux";
import SignUp from "./components/auth/SignUp";
import Vacation from "./components/Form/Vacation";
import axios from "axios";
import Profile from "./components/profile/Profile";
import GroupUser from "./components/manager/GroupUser";
import CreatePer from "./components/manager/CreatePer";
import Permission from "./components/manager/Permission";
import FamilyForm from "./components/Form/familyForm/FamilyForm";
import GroundForm from "./components/Form/groundFrom/GroundForm";
import Form from "./components/Form/Form";
import Table2 from "./components/Table/Table2";

function App() {
  const dispatch = useDispatch();
  const signinurl = "http://192.168.88.100/api/v01/api-login.php";

  const [show, setShow] = useState(true);
  axios.defaults.withCredentials = true;

  useEffect(() => {
    axios.get(signinurl).then((res) => {
      if (res.data.status === 100) {
        setShow(true);
        dispatch(adduser(res.data.data.user_account_profile.username));
        dispatch(addfirstname(res.data.data.user_account_profile.fname));
        dispatch(addlastname(res.data.data.user_account_profile.lname));
        dispatch(addcodemeli(res.data.data.user_account_profile.code));
        dispatch(addphone(res.data.data.user_account_profile.mobile));
        dispatch(addid(res.data.data.user_account_profile.id));
        dispatch(addpassword(res.data.data.user_account_profile.password));
        dispatch(addemail(res.data.data.user_account_profile.email));
        dispatch(sidebg(res.data.data.account_setting.theme_setting.sidebarbg));
        dispatch(sidefont(res.data.data.account_setting.theme_setting.sidefont));
      } else {
        setShow(false);
      }
    });
  }, []);

  // fetch("https://dummyjson.com/products/1")
  //   .then((res) => res.json())
  //   .then((json) => console.log(json));

  return (
    <div className="bg3">
      {show ? (
        <div className="d-flex flex-column " style={{ width: "100%" }}>
          <Topmenu setShow={setShow} />
          <Sidebar />

          <div className="w-75 h-100" style={{ marginTop: "1%", marginLeft: "7%" }}>
            <Routes>
              <Route path="/Map" element={<Map />} />
              <Route path="/Map-list" element={<Table id="tablebg" />} />
              <Route path="/Markedmap" element={<Markedmap />} />
              <Route path="/list" element={<Table2 id="tablebg" />} />
              <Route path="/Users2" element={<Tabs />} />
              <Route path="/mapstates" element={<Maptabs />} />
              <Route path="/form" element={<Form />} />
              <Route path="/familyform" element={<FamilyForm />} />
              <Route path="/groundform" element={<GroundForm />} />
              <Route path="/vacation" element={<Vacation />} />
              <Route path="/manageusers" element={<Manageusers />} />
              <Route path="/groupuser" element={<GroupUser />} />
              <Route path="/Grouplevelper" element={<CreatePer />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/createper" element={<Permission />} />
            </Routes>
          </div>
        </div>
      ) : (
        <SignUp />
      )}
    </div>
  );
}

export default App;
