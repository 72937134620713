import React,{useState} from 'react'
import Accinfo from './Accinfo'
import ChangePass from './ChangePass'
import Theme from './Theme'

const Profile = () => {

    const [tab, setTab] = useState(<Accinfo />)

  return (
        <div className='d-flex w-100 flex-column justify-content-center align-items-center'>
            <div className='d-flex w-auto rounded mt-5 align-items-center justify-content-start' id='shadow' dir='rtl'>
                <button onClick={()=>setTab(<Accinfo />)} className='rounded-end bg-dark p-2 border-start border-secondary'>اطلاعات کاربری</button>
                <button onClick={()=>setTab(<Theme />)} className='bg-dark p-2 border-start border-secondary'>ویرایش نمایه </button>
                <button onClick={()=>setTab(<ChangePass />)} className='bg-dark rounded-start p-2'> تغییر رمز عبور </button>
            </div>
            {tab}
        </div>
  )
}

export default Profile