import React, {useEffect, useState} from "react";
import {Marker, Popup, useMap} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import icon from "./constants";

export default function LocationMarker(props) {
    // const [clickpos, setClickpos] = useState(null);
    const [position, setPosition] = useState(null);
    const map = useMap();


    useEffect(() => {
        map.locate().on("locationfound", function (e) {
            setPosition(e.latlng);
            map.flyTo(e.latlng, map.getZoom(26));
            // const radius = e.accuracy;
            // const circle = L.circle(e.latlng, radius);
            // circle.addTo(map);
        })
    }, [props.numbers]);


    return position === null ? null : (
        <Marker position={position} icon={icon}>
            <Popup>
                A pretty CSS3 popup. <br/> Easily customizable.
            </Popup>
        </Marker>
    );
}