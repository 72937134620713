import axios from 'axios'
import React ,{useEffect,useState,useRef}from 'react'
import { useSelector , useDispatch } from 'react-redux'
import{
  addemail,
  addpassword,
  addfirstname,
  addlastname,
  addphone,
  addpasswordcheck,
  addusername,
  addcodemeli,
  addid,
  userimg
} from "../redux/dataActions"
import logo from '../images/logo.jpg';
import { USER_IMG } from '../redux/dataTypes';

const Accinfo = () => {
  const dispatch = useDispatch()
  const email = useSelector((state)=>state.email)
  const user = useSelector((state)=>state.user)
  const password = useSelector((state)=>state.password)
  const firstname = useSelector((state)=>state.firstname)
  const lastname = useSelector((state)=>state.lastname)
  const codemeli = useSelector((state)=>state.codemeli)
  const user_img = useSelector((state)=>state.user_img)
  const phone = useSelector((state)=>state.phone)
  const id = useSelector((state)=>state.id)
  const [prev , setPrev]= useState(null)
  const fileinputRef = useRef()


  useEffect(() => {
    if(prev){
      const reader = new FileReader();
      reader.onloadend =()=>{
        dispatch(userimg(reader.result))
      }
      reader.readAsDataURL(prev)
    }else{
      console.log('failed')
    }
  }, [prev])
  

console.log(prev)


  const url = 'http://192.168.1.136/api/v01/api-signup.php'          
          // update url

  const data={
    data_option : 'profile_update',
    firstname : firstname,
    username :user,
    lastname : lastname,
    mobile : phone,
    email : email,
    password : password,
    codemeli : codemeli,
    id : id,
    user_img : prev,
  }

  const edituser = ()=>{
    axios.post(url,data).then(res =>{
      console.log(res)
    })
  }

  return (
    <div className='w-75 bg-light rounded d-flex flex-column justify-content-start align-items-center p-3 ' id='shadow' dir='rtl'>
      <div className='d-flex flex-row align-items-center w-100'>
        <label className='w-10'>نام</label>
        <input onChange={(e)=>dispatch(addfirstname(e.target.value))} placeholder={firstname} className='form-control w-35 ms-auto ms-3'/>
        <label className='w-10 '> نام خانوادگی</label>
        <input onChange={(e)=>dispatch(addlastname(e.target.value))} placeholder={lastname} className='form-control w-35 ms-auto me-3'/>
      </div>
      <div className='d-flex flex-row align-items-center w-100 mt-3'>
        <label className='w-10'> ایمیل</label>
        <input onChange={(e)=>dispatch(addemail(e.target.value))} placeholder={email} className='form-control w-35 ms-auto ms-3'/>
        <label className='w-10 '> شماره تماس </label>
        <input onChange={(e)=>dispatch(addphone(e.target.value))} placeholder={phone} className='form-control w-35 ms-auto me-3'/>
      </div>
      <div className='d-flex flex-row align-items-center w-100 mt-3'>
        <label className='w-10 '> کد ملی  </label>
        <input onChange={(e)=>dispatch(addcodemeli(e.target.value))} placeholder={codemeli} className='form-control w-35 ms-auto'/>
        <label className='w-10'>  نام کاربری</label>
        <input placeholder={user} className='form-control w-35 ms-auto ms-3' disabled/>
      </div>
      <div className='d-flex flex-row align-items-center w-100 mt-3'>

        <label className='w-15 text-nowrap'>   تصویر پروفایل</label>
        <input  type="file" ref={fileinputRef} onChange={(e)=>{
          const file = e.target.files[0];
          if(file && file.type.substring(0,5)=== "image"){
            setPrev(file)
          }else{
            <></>
          }
        }} accept='image/*' className='form-control w-30 ms-auto ms-3' />
        <img className='rounded-circle' src={user_img} width={40} height={40}/>
      </div>
      <button className='p-2 text-light rounded mt-3 me-auto ms-4' style={{backgroundColor:'#4276DD'}} onClick={edituser}>
        ویرایش اطلاعات
      </button>
     
      
    </div>
  )
}

export default Accinfo