import axios from 'axios'
import {useEffect, useState} from 'react';
import $ from "jquery";

const GroupUser = () => {
    axios.defaults.withCredentials = true;
    const [name, setName] = useState('')
    const [gruops, setGroups] = useState([])

    const url = 'http://192.168.1.136/api/v01/api-select.php'

    useEffect(() => {
        axios.post(url, {
            data_option: 2,
        }).then(res => {
            for (let i = 0; i < res.data.data.permission_groupe_name.length; i++) {
                setGroups(current => [...current, res.data.data.permission_groupe_name[i]])
            }
        })
    }, [])


    const save = () => {
        if (name != '') {
            $.ajax({
                type: 'POST',
                url: url,
                data: {groupName: name},
                success: function (res) {
                    console.log(res)
                }
            })
        } else {
            alert('empty')
        }
    }

    return (
        <div
            className='d-flex flex-column justify-content-center align-items-end w-75 bg-light text-dark ms-auto p-3 rounded'
            style={{marginTop:80}}
            id='shadow'>
            <div className='d-flex flex-row w-100 align-items-center' dir='rtl'>
                <div className='ms-4'>نام گروه :</div>
                <input className='form-control w-25 h-50' onChange={(e) => setName(e.target.value)}/>
                <button className='btn text-light me-auto' style={{backgroundColor: '#0077B6'}} onClick={save}>افزودن
                </button>
            </div>
            <div className='d-flex flex-column w-100 my-3'>
                <div className='mx-auto text-info p-3' style={{borderBottom: '1px solid lightblue'}}>نام گروه ها</div>
                {gruops.map((el) => (
                    <div className='w-25 my-2 ms-auto text-end'>{el.groupe_name}</div>
                ))}</div>
        </div>
    )
}

export default GroupUser