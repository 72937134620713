import React, {useEffect, useState} from 'react'
import {faCircleXmark} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import "../auth/auth.css"
import axios from 'axios'
import {
    addcodemeli,
    addemail,
    addfirstname,
    addlastname,
    addpassword,
    addpasswordcheck,
    addphone,
    addusername
} from "../redux/dataActions"
import {useDispatch, useSelector} from 'react-redux'
import $ from "jquery";

const Adduser = (props) => {
    const dispatch = useDispatch()
    const email = useSelector((state) => state.email)
    const password = useSelector((state) => state.password)
    const firstname = useSelector((state) => state.firstname)
    const lastname = useSelector((state) => state.lastname)
    const codemeli = useSelector((state) => state.codemeli)
    const phone = useSelector((state) => state.phone)
    const username = useSelector((state) => state.username)
    const [emailerr, setEmailerr] = useState(null)
    const [phoneErr, setPhoneErr] = useState(null)
    const [passwordErr, setPasswordErr] = useState(null)

    const data = {
        data: {
            firstname: firstname,
            username: username,
            lastname: lastname,
            mobile: phone,
            email: email,
            password: password,
            codemeli: codemeli
        }

    }


    const adduser = () => {
        if (!firstname) {
            alert("نام کاربری را وارد کنید")
        } else if (!lastname) {
            alert("نام خانوادگی را وارد کنید")
        } else if (!email) {
            alert("ایمیل خود را وارد کنید")
        } else if (!phone) {
            alert("شماره تماس خود را وارد کنید")
        } else {

            axios.post('http://192.168.1.139/api/arrays.php', data)
                .then(function (response) {
                    props.refresh()
                    props.show("#adduser")
                    $(document).ready(function () {
                        $('input').val("");
                    });
                })
        }
    }


    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };


    useEffect(() => {
        if (!(phone.match('[0-9]{11}'))) {
            setPhoneErr('شماره همراه صحیح نیست')
        } else {
            setPhoneErr(null)
        }
    }, [phone])

    useEffect(() => {
        if (validateEmail(email)) {
            setEmailerr(null)
        } else {
            setEmailerr('ایمیل صحیح نیست')
        }
    }, [email])


    return (
        <div id='shadow'
             className='d-flex w-40 bg-light flex-column m-auto justify-content-center align-items-end p-3 rounded'>
            <FontAwesomeIcon id='close' style={{color: 'blue'}} className='mx-2 align-middle fa-lg'
                             onClick={() => props.show("#adduser")} icon={faCircleXmark}/>
            <div style={{fontSize: '20px', fontWeight: '600'}} className="ms-auto">
                افزودن اکانت
            </div>
            <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                <div className='d-flex flex-column w-40 mx-3'>
                    <label className='mt-3'>نام </label>
                    <input onChange={(e) => dispatch(addfirstname(e.target.value))}
                           className='form-control w-100 mt-2 text-end'/>
                </div>
                <div className='d-flex mx-3 flex-column w-40'>
                    <label className='mt-3'>نام خانوادگی </label>
                    <input onChange={(e) => dispatch(addlastname(e.target.value))}
                           className='form-control w-100 mt-2 text-end'/>
                </div>
            </div>

            <div className='d-flex flex-row justify-content-end align-items-center w-100' dir='ltr'>
                <div className='d-flex flex-column w-40 mx-3'>
                    <label className='mt-3 d-flex flex-row w-100'>
                        {phoneErr === null ? <></> : <div className='' style={{
                            color: 'red',
                            fontSize: '12px',
                            fontWeight: '700',
                            whiteSpace: 'nowrap'
                        }}> {phoneErr}</div>}
                        <div className='ms-auto text-nowrap'>شماره تماس</div>
                    </label>
                    <input onChange={(e) => dispatch(addphone(e.target.value))}
                           className='form-control w-100 mt-2 text-end' placeholder='09... '/>
                </div>
                <div className='d-flex flex-column w-40 mx-3'>
                    <label className='mt-3 d-flex flex-row w-100'>
                        {emailerr === null ? <></> : <div className='me-5' style={{
                            color: 'red',
                            fontSize: '12px',
                            fontWeight: '700'
                        }}> {emailerr}</div>}
                        <div className='ms-auto '>ایمیل</div>
                    </label>
                    <input onChange={(e) => dispatch(addemail(e.target.value))} id='email'
                           className='form-control w-100 mt-2 text-end' placeholder='ایمیل'/>
                </div>
            </div>

            <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                <div className='d-flex flex-column w-40 mx-3'>
                    <label className='mt-3'>نام کاربری</label>
                    <input onChange={(e) => dispatch(addusername(e.target.value.trim()))} value={username}
                           className='form-control w-100 mt-2 text-end' placeholder='نام کاربری'/>
                </div>
                <div className='d-flex flex-column w-40 mx-3'>
                    <label className='mt-3'> کد ملی</label>
                    <input onChange={(e) => dispatch(addcodemeli(e.target.value.trim()))} value={codemeli}
                           className='form-control w-100 mt-2 text-end' placeholder=' کد ملی'/>
                </div>
            </div>


            <div className='d-flex flex-row justify-content-start align-items-center w-100' dir='rtl'>
                <div className='d-flex flex-column w-40 mx-3'>
                    <label className='mt-3'>پسوورد</label>
                    <input onChange={(e) => dispatch(addpassword(e.target.value))}
                           className='form-control w-100 mt-2 text-end' type='password' placeholder='پسوورد'/>
                </div>
                <div className='d-flex flex-column w-40 mx-3'>
                    <label className='mt-3 d-flex flex-row w-75'>
                        <div className='ms-auto text-nowrap'>تکرار پسوورد</div>
                        {passwordErr === null ? <></> : <div className='me-5 text-nowrap' style={{
                            color: 'red',
                            fontSize: '12px',
                            fontWeight: '700'
                        }}> {passwordErr}</div>}
                    </label>
                    <input onChange={(e) => dispatch(addpasswordcheck(e.target.value))}
                           className='form-control w-100 mt-2 text-end' type='password' placeholder='پسوورد'/>
                </div>
            </div>


            <div className='d-flex flex-row justify-content-between w-100'>
                <button id='button' className='me-auto' onClick={adduser}> افزودن اکانت</button>
            </div>
        </div>
    )
}

export default Adduser