import React, {useState} from 'react'
import Adduser from './Adduser'
import Table from './Table';
import './Tabs.css';
import Error from './Error';
import {DateRangePicker} from "react-advance-jalaali-datepicker";


const Tabs = () => {

    const [tab, setTab] = useState(<Adduser/>)

    return (
        <div className='text-dark d-flex flex-column justify-content-center w-100 h-100 align-items-center '
             id='container' dir='rtl'>
            <div className='d-flex flex-row ms-auto h-100' id='radius'>
                <button onClick={() => {
                    setTab(<Adduser/>)
                }} className='p-2 rounded-end px-4'>tab1
                </button>
                <button onClick={() => {
                    setTab(<Error/>)
                }} className='p-2 px-4'>ارور
                </button>
                <button onClick={() => {
                    setTab(<Table id="tablebg2"/>)
                }} className='p-2 px-4'>تیبل
                </button>
                <button onClick={() => {
                    setTab(<DateRangePicker
                        placeholder="انتخاب تاریخ"
                        format="jYYYY/jMM/jDD"
                        id="datePicker"
                        preSelected="1396/05/15"/>)
                }} className='p-2 rounded-start px-4'>جدول
                </button>
            </div>
            <div className='w-100' id='tab'>{tab}</div>
        </div>
    )
}

export default Tabs